import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Dropdown, Modal, ModalHeader } from "reactstrap";
import { getMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { NavLink } from "react-router-dom";

export default function Wallet(props) {
  const [modal, setModal] = useState(false);
  // const [users, setUsers] = useState([]);
  // const [wallete, setWallete] = useState([]);
  const [commission, setCommision] = useState([]);
  const [wallets, setWallet] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null); // Track selected user
  const [alldata, setAlldata] = useState([]); // Track selected user
  const [isLoading, setIsLoading] = useState(true);
  const [showNoRecords, setShowNoRecords] = useState(false);

  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role: null; 
  
  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null; 



  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };
    useEffect(() => {
      fetchAmount();
     
      const loadingTimer = setTimeout(() => {
        if (isLoading) {
          setIsLoading(false);
          setShowNoRecords(true);
        }
      }, 3000);
      return () => clearTimeout(loadingTimer);
    }, [isLoading]);


    const fetchAmount = async () => {
      try {
        const response = await getMethod(Apiurl.wallet);
        if (response) {
          setWallet(response.data);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
  return (
    <>
      <>
          <Header/>
        <Sidebar isSidebarOpen={props.isSidebarOpen}/>
        <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>

          <Modal size="ig" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>
              <form
                style={{ margin: "10px 70px" }}
                className="login"
                //   onSubmit={handleSubmit}
              >
                <div className="box" style={{}}>
                  <label style={{ fontSize: "16px", display: "block" }}>
                    Total Amount
                  </label>

                  <input
                    type="text"
                    className="input rounded"
                    //   value={name}
                    //   onChange={(event) => handlechange("name", event)}
                    placeholder="Enter your username"
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  />

                  {/* {errors.name && <p style={{marginBottom:'3px'}} className="errorMsg text-danger text-bold">{errors.name}</p>} */}
                </div>

                <div className="modal-footer d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-secondary "
                    data-bs-dismiss="modal"
                    onClick={() => setModal(!modal)}
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </ModalHeader>
          </Modal>

          <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
            <div className="row">
              <div className="col-12">
                <div className="card my-4">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{height:"55px"}}>
                      <h6 className="text-white text-capitalize ps-3 mt-3">
                        TRANSACTION DETAIL
                      </h6>
                    </div>
                  </div>
                  <div className="card-body px-0 pb-2">
                    <div className="table-responsive p-0">
                      {
                    userEmail1 === "superadmin@gmail.com" ||  userEmail === "Admin" ? (
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Transaction Id
                            </th>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Terminal Name
                            </th>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Terminal Id
                            </th>
                            
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Amount
                            </th>

                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              User Name
                            </th>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Shared Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table align-items-center">

                          
                        {
                             isLoading ? (
                              
                              <tr>
                              <td colSpan="6" className="text-center">
                                <span className="loader"></span>
                             
                              </td>
                            </tr>
                             // replace this with your custom loader component
                           )  : showNoRecords ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <h1>No Records Found</h1>
                                </td>
                              </tr>
                            ) : (
                          wallets?.map((wallet, index) => (
                            wallet?.transaction !== null ?(
                            wallet.amount >= 0 && (

                              <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {wallet?.transaction_id}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {wallet?.transaction?.user_main?.user?.name}
                                  </h6>
                                </td>
                                <td>
                                <NavLink to={`/activeposition/${wallet?.transaction?.terminal_id}`}target="_blank"><h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(wallet?.transaction?.user_main?.t_status),}} >
                                                        {wallet?.transaction?.terminal_id}
                                                        </h6>
                                                      </NavLink>
                                                      </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {wallet?.transaction?.amount}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {wallet?.user?.name}
                                  </h6>
                                </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                  {wallet?.amount >= 0 ? (
                                    <p className="font-weight-bold mb-0 ms-2">{wallet?.amount}</p>
                                  ) : (
                                    <p className="font-weight-bold mb-0 ms-2"></p>
                                  )}
                                  </h6>
                                </td>
                              </tr>
                            </React.Fragment>



                            )

                            ):null
                        
                          ))
                        )}
                        </tbody>
                      </table>
                    ) :(
                      <table className="table align-items-center mb-0">
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Transaction Id
                          </th>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Terminal Name
                          </th>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Terminal Id
                          </th>
                          
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Amount
                          </th>

                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            User Name
                          </th>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Shared Amount
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table align-items-center">

                        
                      {
                           isLoading ? (
                            <tr>
                            <td colSpan="6" className="text-center">
                              <span className="loader"></span>
                            </td>
                          </tr> // replace this with your custom loader component
                          ) : (
                        wallets?.map((wallet, index) => (
                          wallet?.transaction !== null ?(
                          wallet.amount >= 0 && (
                            <React.Fragment key={index}>
                            <tr>
                              <td>
                                <h6 className="font-weight-bold mb-0 ms-2">
                                  {wallet?.transaction_id}
                                </h6>
                              </td>
                              <td>
                                <h6 className="font-weight-bold mb-0 ms-2">
                                  {wallet?.transaction?.user_main?.user?.name}
                                </h6>
                              </td>
                              <td>
                                <NavLink to={`/activeposition/${wallet?.transaction?.terminal_id}`}target="_blank"><h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(wallet?.transaction?.user_main?.t_status),}} >
                                                        {wallet?.transaction?.terminal_id}
                                                        </h6>
                                                      </NavLink>
                                                      </td>
                              <td>
                                <h6 className="font-weight-bold mb-0 ms-2">
                                  {wallet?.transaction?.amount}
                                </h6>
                              </td>
                              <td>
                              
                                <h6 className="font-weight-bold mb-0 ms-2">
                                  {wallet?.user?.name}
                                </h6>
                              
                              </td>
                              <td>
                                <h6 className="font-weight-bold mb-0 ms-2">
                                {wallet?.amount >= 0 ? (
                                  <h6 className="font-weight-bold mb-0 ms-2">{wallet?.amount}</h6>
                                ) : (
                                  <h6 className="font-weight-bold mb-0 ms-2"></h6>
                                )}
                                </h6>
                              </td>
                            </tr>
                          </React.Fragment>



                          )

                          ):null
                        ))
                      )}
                      </tbody>
                    </table>
                    )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    </>
  );
}
