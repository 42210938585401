import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { Dropdown, Modal, ModalHeader } from "reactstrap";
import { deleteMethod, getMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Showdilog } from "../service/alert_service";
import { NavLink } from "react-router-dom";

export default function History(props) {
  const [histories, setHistories] = useState([]);

  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role: null; 
  
  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null; 

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchHistory();
  }, []);


  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };

  const fetchHistory = async () => {
    try {
      // Make an API request to fetch user data
      const response = await getMethod(Apiurl.history);
      if (response) {
        setHistories(response.data);
        setIsLoading(false);
      }

    } catch (error) {
      setIsLoading(false);

    }
  };

  const handldelete = async (id) => {

    const response = await deleteMethod(`${Apiurl.history}/${id}`);
    if (response.success) {
      Showdilog("History is Deleted");

      setTimeout(() => {
        window.location.href = "/history";
      }, 3000);
    } else {
      Showdilog(response.message, "failed");
    }
  };

  return (
    <>
      <>
          <Header/>
        <Sidebar isSidebarOpen={props.isSidebarOpen}/>
        <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
          <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
            <div className="row">
              <div className="col-12">
                <div className="card my-4">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{height:"55px"}}>
                      <h6 className="text-white text-capitalize ps-3 mt-3">
                        HISTORY
                      </h6>
                    </div>
                  </div>
                  <div className="card-body px-0 pb-2 table-responsive p-0">
                   
                      
                    {
                           
                      
                    userEmail1 === "superadmin@gmail.com" ||  userEmail === "Admin" ? (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Id
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal Id
                              </th>

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal Amount
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table align-items-center">
                            {
                          isLoading ? (
                              <tr>
                              <td colSpan="6" className="text-center">
                                <span className="loader"></span>
                              </td>
                            </tr>
     // replace this with your custom loader component
                            ) : (
                            histories.map((history, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {index + 1}
                                    </h6>
                                  </td>

                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {history?.user_main?.user?.name}
                                    </h6>
                                  </td>
<td>
                                  <NavLink to={`/activeposition/${history.terminal_id}`}target="_blank">  <h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(history.user_main.t_status),}} >
                                      {history?.terminal_id}
                                    </h6>
                                    </NavLink>
                                    </td>
                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {history?.amount}
                                    </h6>
                                  </td>
                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      <span
                                        title="Delete"
                                        style={{ color: "red" }}
                                        onClick={() => handldelete(history.id)}
                                      >
                                        <i
                                          className="fa fa-trash fa-lg mt-2 me-2 ms-3"
                                          aria-hidden="true"
                                        ></i>
                                      </span>
                                    </h6>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Id
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal Id
                              </th>

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal Amount
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table align-items-center">
                            {histories.map((history, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {index + 1}
                                    </h6>
                                  </td>

                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {history?.user_main?.user?.name}
                                    </h6>
                                  </td>

                                  <td>
                                  <NavLink to={`/activeposition/${history.terminal_id}`}target="_blank">  <h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(history.user_main.t_status),}} >
                                      {history?.terminal_id}
                                    </h6>
                                    </NavLink>
                                    </td>

                                  <td>
                                    <h6 className=" font-weight-bold mb-0 ms-2">
                                      {history?.amount}
                                    </h6>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      )
                    
                            }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </main>
      </>
    </>
  );
}
