
import axios from 'axios';
import { Apiurl } from './apiRoute';
import { Cookies  } from 'react-cookie';


export const getMethod = async (url) => {

    const cookies = new Cookies();

    const login_token = cookies.get("token");

    if (login_token !== null) {
        var header = { Authorization: login_token };
    }

    const data = await axios.get(Apiurl.routePath+url, { headers: header }).then(response => {
        return response ;
    }).catch(error => {
        return error.response.data;
    });

    return data.data;

};

export const postMethod = async (url, params) => {
    const cookies = new Cookies();
    const login_token = cookies.get("token");
    // const login_token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiZW1haWwiOiJhZG1pbkBnbWFpbC5jb20iLCJwYXNzd29yZCI6ImV5SjBlWEFpT2lKS1YxUWlMQ0poYkdjaU9pSklVekkxTmlKOS5Ja0ZrYldsdVFERXlNeUkueExoY1k2dEZYcjZ2Vm1rdF8xVHVGdV9fS3FPd0VOLW5GRWkwY1pLT19WVSJ9.H4Sp6a7-t8NuzzbONSlSTch05w1QHjF6qC6udowMxo4";

    if (login_token !== null) {
        var header = { Authorization: login_token };
    }
    const Response = await axios.post(Apiurl.routePath + url, params, { headers: header }).then(response => {
        return response.data;
    }).catch(error => {
        return error?.response?.data;
    });
    return Response;
};

export const deleteMethod = async (url) => {
    const cookies = new Cookies();
    const login_token =  cookies.get("token");  
    if (login_token !== null) {
      var headers = {Authorization: login_token};
    }
    const response = await axios.delete(Apiurl.routePath + url, { headers });
    return response.data;
  };

export const putMethod = async (url, params) => {
    const cookies = new Cookies();
    const login_token =  cookies.get("token");
    const headers = {Authorization: login_token};
    const data = await axios.put(Apiurl.routePath + url, params, {headers });
    return data;
};