import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Modal, ModalHeader } from "reactstrap";
import { deleteMethod, getMethod, postMethod, putMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Showdilog, showAlert, showConfirmationDialog } from "../service/alert_service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Loader.css";
import { NavLink } from "react-router-dom";
import moment from "moment";
import './Userdetail.css';
import Select from 'react-select';
import { useParams } from 'react-router-dom';


export default function UsedetailsTable(props) {

  const routePath = Apiurl.routePath;

  const { type } = useParams();
  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;

  const [isLoading, setIsLoading] = useState(true);
  const [userdata, setUserData] = useState(false);
  const [usercommission, setCommission] = useState([]);

  const [usermodal, setUsermodal] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalAddAcount, setModalAddAcount] = useState(false);
  const [ModalAddCommission, setModalAddCommission] = useState(false);
  const [modalAddCurrency, setModaAddCurrency] = useState(false);
  const [modalUpload, setModaUpload] = useState(false);
  const [modalDownload, setModalDownload] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [note, setNote] = useState("");
  const [entrydate, setEntrydate] = useState(null);
  const [bankemail, setBankEmail] = useState("");
  const [bankpassword, setBankPassword] = useState("");
  const [contactnumber, setContactNumber] = useState("");
  const [role, setRole] = useState("");
  const [addmainusers, setMainUsers] = useState([]);
  const [selectedMainUser, setSelectedMainUser] = useState();

  const [selectedUser, setSelectedUser] = useState(null); // Track selected user
  const [showPassword, setShowPassword] = useState({});
  const [showPassword2, setShowPassword2] = useState({});

  // Adduser Accout

  const [username, setUsername] = useState();
  const [terminalid, setTerminalid] = useState("");
  const [terminalpassword, setTerminalPassword] = useState("");
  const [terminalpasswordreadonly, setTerminalPasswordReadOnly] = useState("");
  const [entrydates, setEntrydates] = useState("");
  const [tsource, setTsource] = useState("");
  const [tpc, setTpc] = useState("");
  const [tstatus, setTStatus] = useState("");
  const [iblist, setIbList] = useState("");

  const [tpcName, setTpcName] = useState();
  const [tsourceName, setTsourceName] = useState();
  const [IbName, setIbName] = useState();


  const [tpclist, setTpcList] = useState();
  const [tsourcelist, setTsourceList] = useState();

  const [addUser, setAddUser] = useState();

  const [nameForEditUser, setNameForEditUser] = useState("");

  // Addusercommission  

  const [Commisionusername, setCommissionUsername] = useState();
  const [commisionterminalid, setCommissionTerminalid] = useState("");
  const [sharingpercentage, setSharingPercent] = useState("");
  const [commisionnote, setCommissionNote] = useState("");
  const [commissionentrydate, setCommissionEntrydate] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");

  //add terminal amount
  const [errors, setErrors] = useState({});
  const [searchInput, setSearchInput] = useState("");

  const [dropdown1, setDropdown] = useState([]);

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageTsource, setCurrentPageTsource] = useState(1)
  const [recordsPerPageTsource, setRecordsPerPageTsource] = useState(5000);
  const [currentPageTpc, setCurrentPageTpc] = useState(1)
  const [recordsPerPageTpc, setRecordsPerPageTpc] = useState(50000);

  const [numbers, setNumbres] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalPage, setTotalPage] = useState();

  const [uidcurrency, setUidForCurrency] = useState();
  const [tidcurrency, setTidForCurrency] = useState();
  const [currencyType, setCurrecyType] = useState([]);
  const [currencyData, setCurrencyData] = useState();
  const [countValues, setCountValues] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);


  const [file, setFile] = useState();

  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };

  useEffect(() => {
    fetchTpc()
    fetchTsource()
    fetchCurrency()
  }, [])

  useEffect(() => {
    fetchMainUsers(type);
  }, [currentPage, recordsPerPage, searchInput, type]);

  const fetchMainUsers = async (type) => {
    try {
      if (type === 'bot') {
        const response = await getMethod(`${Apiurl.set_bot}?page=${currentPage}&limit=${recordsPerPage}&search=${searchInput}`)
        if (response) {
          setIsLoading(false)
        }
        setMainUsers(response.data.data);
        const numbers = response.data.total_page
        setTotalPage(numbers)
        const numbersArray = Array.from({ length: numbers }, (_, index) => index + 1);
        setNumbres(numbersArray)
      }
      else if (type === 'Ib') {
        const response = await getMethod(`${Apiurl.IbUsers}?page=${currentPage}&limit=${recordsPerPage}&search=${searchInput}`)
        if (response) {
          setIsLoading(false)
        }
        setMainUsers(response.data.data);
        const numbers = response.data.total_page
        setTotalPage(numbers)
        const numbersArray = Array.from({ length: numbers }, (_, index) => index + 1);
        setNumbres(numbersArray)
      }
      else {
        const response = await getMethod(`${Apiurl.mainuser}?page=${currentPage}&limit=${recordsPerPage}&search=${searchInput}`)
        if (response) {
          setIsLoading(false)
        }
        setMainUsers(response.data.data);
        const numbers = response.data.total_page
        setTotalPage(numbers)
        const numbersArray = Array.from({ length: numbers }, (_, index) => index + 1);
        setNumbres(numbersArray)
      }

    } catch (error) {
    }
  };

  const handleSearch = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchInput(input);
    setCurrentPage(1)
  };

  // User register Acount

  const handlechange = (item, e) => {
    const stateSetters = {
      name: setName,
      email: setEmail,
      password: setPassword,
      note: setNote,
      bankemail: setBankEmail,
      bankpassword: setBankPassword,
      contactnumber: setContactNumber,
      role: setRole
    };

    const setter = stateSetters[item];
    if (setter) {
      setter(e.target.value);
    }
  };

  const handleSetmodal = () => {
    setModal(true); 
    setErrors('')
    setName('')
    setEmail("")
    setPassword("")
    setNote("")
    setEntrydate("")
    setBankEmail("")
    setBankPassword("")
    setContactNumber("")
    setRole("")
  }

  //  ------user submit function-------

  const handleSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!name) {
      errors.name = "Please Enter the Name";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }
    if (!password) {
      errors.password = "Password is required";
    }

    if (!role) {
      errors.role = "Role is Required";
    }
    if (!entrydate) {
      errors.entrydate = "Date is Required";
    }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      // showAlert("Please enter proper data.", "error");
      return;
    }

    let entryDateISO = "";

    if (entrydate && !isNaN(entrydate.getTime())) {
      entryDateISO = entrydate.toISOString();
    }

    const data = {
      name: name,
      email: email,
      password: password,
      note: note,
      entrydate: entryDateISO,
      bank_email: bankemail,
      bank_password: bankpassword,
      contact_number: contactnumber,
      role: role,
      status: "Active",
    };
    data.entrydate = entrydate ? (new Date(new Date(data.entrydate).getTime() + 5.5 * 60 * 60 * 1000).toISOString()) : "";
    try {
      setIsLoading(true);
      setTimeout(async () => {
        const response = await postMethod(Apiurl.register, data);
        if (response.success) {
          Showdilog("Add User successful");
          setModal(false)
          fetchMainUsers()
          // setTimeout(() => {
          //   window.location.href = "/userdetails/bot";
          // }, 2000);
        } else {
          Showdilog(response.message, "Registration failed");
        }
      }, 1000);
    } catch (error) {

    } finally {
      setIsLoading(false);
    }
  };

  //**************** User Edit model ******************

  const useredite = (id) => {
    resetForm();
    setUsermodal(true);
    const UserData = addmainusers.find((user) => user.id === id);

    setUserData(UserData);
    setName(UserData.name);
    setEmail(UserData.email);
    setPassword(UserData.password);

    if (UserData.entrydate) {
      const serverDate = moment(UserData.entrydate, 'YYYY-MM-DD');
      if (serverDate.isValid()) {
        const localDate = serverDate.utcOffset(serverDate.utcOffset());
        setEntrydate(localDate.toDate());
      } else {
        console.error("Invalid entrydate:", UserData.entrydate);
      }
    } else {
      setEntrydate("");
    }
    setBankEmail(UserData.bank_email)
    setBankPassword(UserData.bank_password)
    setContactNumber(UserData.contact_number)
    setNote(UserData.note);
    setRole(UserData.role.toLowerCase());
  };

  const handleUserEditSubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!name) {
      errors.name = "Please Enter the Name";
    }
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email address";
    }
    if (!password) {
      errors.password = "Password is required";
    }

    if (!role) {
      errors.role = "Role is Required";
    }
    if (!entrydate) {
      errors.entrydate = "Date is Required";
    }


    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      return;
    }

    let entryDateISO = "";

    if (entrydate && !isNaN(entrydate.getTime())) {
      entryDateISO = entrydate.toISOString();
    }

    const data = {
      name: name,
      email: email,
      password: password,
      note: note,
      entrydate: entryDateISO,
      role: role,
      bank_email: bankemail,
      bank_password: bankpassword,
      contact_number: contactnumber
    };
    data.entrydate = entrydate ? (new Date(new Date(data.entrydate).getTime() + 5.5 * 60 * 60 * 1000).toISOString()) : "";
    const response = await putMethod(`${Apiurl.userupdate}/${userdata.id}`, data);
    if (response) {
      setUsermodal(false);
      showAlert(response.data.message, "success");
      fetchMainUsers(type)
      resetForm();
    }
  };

  const resetForm = () => {
    setName(null);
    setEmail(null);
    setPassword(null);
    setEntrydate("");
    setBankEmail('')
    setBankPassword('')
    setContactNumber('')
    setNote(null);
    setRole(null);
  }

  const closeModelReset = () => {
    setUsermodal(!usermodal)
    resetForm();
    setErrors({})

  }

  // to set prefilled data on edit terminal popup

  const FileUploadModal = (uid, tid) => {
    setModaUpload(true)
    setUidForCurrency(uid)
    setTidForCurrency(tid)
    setFile()
  }

  const FileDownloadModal = (uid, tid) => {
    setUidForCurrency(uid)
    setTidForCurrency(tid)
    setModalDownload(true)
  }


  const AddModalAddAcount1 = (id) => {
    setErrors('')
    setShowPassword2({})
    setModalEdit(true);
    const editTerminalData = addmainusers.find((user) => user.user_mains.some(main => main.terminal_id === id));
    if (editTerminalData) {
      const filteredUserMains = editTerminalData.user_mains.filter(main => main.terminal_id === id);
      // const serverDate = moment(filteredUserMains[0].entrydate, 'YYYY-MM-DD');
      // const localDate = serverDate.utcOffset(serverDate.utcOffset());
      // setEntrydates(localDate.toDate());

      if (filteredUserMains[0].entrydate) {
        const serverDate = moment(filteredUserMains[0].entrydate, 'YYYY-MM-DD');
        if (serverDate.isValid()) {
          const localDate = serverDate.utcOffset(serverDate.utcOffset());
          setEntrydates(localDate.toDate());
        } else {
          console.error("Invalid entrydate:", filteredUserMains[0].entrydate);
        }
      } else {
        setEntrydates("");
      }

      const tpcid = filteredUserMains[0].t_pc_id;
      const tpcName = tpclist.find((tpc) => tpc.value == tpcid)
      setTpcName(tpcName?.label)

      const tsourceid = filteredUserMains[0].t_source_id;
      const tsourceName = tsourcelist.find((tsource) => tsource.value == tsourceid)
      setTsourceName(tsourceName?.label)

      const ibid = filteredUserMains[0].ib_id;
      const ibName = dropdown1.find((ib) => ib.value == ibid)
      setIbName(ibName?.label)

      setTerminalPassword(filteredUserMains[0].terminal_pass);
      setTerminalPasswordReadOnly(filteredUserMains[0].terminal_read_only_password);
      setTsource(filteredUserMains[0].t_source_id);
      setTpc(filteredUserMains[0].t_pc_id);
      setTerminalid(filteredUserMains[0].terminal_id);
      setTStatus(filteredUserMains[0].t_status);
      setIbList(filteredUserMains[0].ib_id);
    }
  };

  // submit function for edit terminal popup 

  const AddUserMainhandlesubmit1 = async (event, terminalid) => {
    event.preventDefault();
    const errors = {};
    if (!terminalid) {
      errors.terminalid = "terminalid  is required";
    }
    if (!terminalpassword) {
      errors.terminalpassword = "Password  is required";
    }
    if (!terminalpasswordreadonly) {
      errors.terminalpasswordreadonly = "Read only password is required";
    }
    if (!entrydates) {
      errors.entrydates = "Date is required";
    }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      // showAlert("Please enter proper data.", "error");
      return;
    }
    let entryDatesISO = "";

    if (entrydates && !isNaN(entrydates.getTime())) {
      entryDatesISO = entrydates.toISOString();
    }
    const res = {
      terminal_pass: terminalpassword,
      terminal_read_only_password: terminalpasswordreadonly,
      entrydate: entryDatesISO,
      t_source_id: tsource === ("" || "0") ? null : tsource,
      t_pc_id: tpc ===  "" ? null : tpc,
      t_status: tstatus === "" ? null : tstatus,
      ib_id: iblist
    };
    res.entrydate = new Date(new Date(res.entrydate).getTime() + 5.5 * 60 * 60 * 1000).toISOString();
    const response = await putMethod(`${Apiurl.terminalpassword}/${terminalid}`, res);
    if (response) {
      fetchMainUsers(terminalid);
      showAlert(response.data.message, "success");
      setModalEdit(false);
      setErrors({})
      setTpc("")
      setTsource("")
      setSelectedUser(null);
    }
  };

  const addacounthandlechange = (field, event) => {
    const stateSetters = {
      username: setUsername,
      terminalid: setTerminalid,
      terminalpassword: setTerminalPassword,
      terminalpasswordreadonly: setTerminalPasswordReadOnly,
      entrydates: setEntrydates,
      tsource: setTsource,
      tpc: setTpc,
      tstatus: setTStatus,
      iblist: setIbList
    };

    const setter = stateSetters[field];
    if (setter) {
      setter(event.target.value);
    }
  };

  const AddUserMainhandlesubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!terminalid) {
      errors.terminalid = "Terminal Id is required";
    }
    if (!terminalpassword) {
      errors.terminalpassword = "Password  is required";
    }
    if (!terminalpasswordreadonly) {
      errors.terminalpasswordreadonly = " Read Only password is required";
    }
    if (!entrydates) {
      errors.entrydates = "Date is required";
    }
    // if (!tsource) {
    //   errors.tsource = "tsource is required";
    // }
    // if (!tpc) {
    //   errors.tpc = "tpc is required";
    // }
    // if (!tstatus) {
    //   errors.tstatus = "Please select status";
    // }
    // if (!iblist) {
    //   errors.iblist = "Please select user name";
    // }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      // showAlert("Please enter proper data.", "error");
      return;
    }

    let entryDatesISO = "";

    if (entrydates && !isNaN(entrydates.getTime())) {
      entryDatesISO = entrydates.toISOString();
    }

    const res = {
      user_id: addUser,
      terminal_id: terminalid,
      terminal_pass: terminalpassword,
      terminal_read_only_password: terminalpasswordreadonly,
      entrydate: entryDatesISO,
      status: "Active",
      t_source_id: tsource === "" ? null : tsource,
      t_pc_id: tpc === "" ? null : tpc,
      t_status: tstatus === "" ? null : tstatus,
      ib_id: iblist

    };
    res.entrydate = new Date(new Date(res.entrydate).getTime() + 5.5 * 60 * 60 * 1000).toISOString();
    try {
      const response = await postMethod(Apiurl.mainuser, res);
      if (response.success) {
        Showdilog("Add Main User successful");
        fetchMainUsers()
        setModalAddAcount(false)
        setTpc("")
        setTsource("")
        setSelectedUser(null);
        // setTimeout(() => {
        //   window.location.href = "/userdetails/bot";
        // }, 2000);
      } else {
        Showdilog(response.message);
      }
    } catch (error) {
    }
  };

  //********** Add  User Commission******************************************************************

  const addcommisionhandlechange = (field, event) => {
    const stateSetters = {
      Commisionusername: setCommissionUsername,
      commisionterminalidate: () =>
        setCommissionTerminalid(selectedUser?.terminal_id),
      sharingpercentage: setSharingPercent,
      commisionnote: setCommissionNote,
      commissionentrydate: setCommissionEntrydate,
    };

    const setter = stateSetters[field];
    if (setter) {
      setter(event.target.value);
    }
  };

  const AddCommisionsubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!sharingpercentage) {
      errors.sharingpercentage = "Enter valid sharing percentage Value";
    }
    if (!selectedUserName) {
      errors.selectedUserName = "User Name is required";
    }
    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      return;
    }

    const res = {
      userId: parseInt(selectedUserName),
      masterUserId: selectedMainUser.user_id,
      terminalId: selectedMainUser.terminal_id,
      percentage: parseInt(sharingpercentage),
      note: commisionnote,
      entrydate: commissionentrydate,
    };
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.accountcommistion, res);
      if (response.success) {
        Showdilog("User Commission successfully Added");
        // AddmodalCommission(selectedMainUser);
        setSelectedUserName("");
        setSharingPercent("");
        setCommissionNote("");
        setCommissionEntrydate("");
        setModalAddCommission(false);
      } else {
        Showdilog(response.message);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowClick = (user) => {
    
    dropDown(user)
    setSelectedUser([user]);

    if (selectedUser && selectedUser[0]?.id === user.id) {
      setSelectedUser(null);
    } else {
      setSelectedUser([user]);
    }
  }

  const handlePasswordClick2 = (mainuserId) => {
    setShowPassword2((prevShowPassword2) => ({
      ...prevShowPassword2,
      [mainuserId]: !prevShowPassword2[mainuserId],
    }));
  };

  const dropDown = async (user) => {
    try {
      const response = await getMethod(Apiurl.dropdown_data)
      const dropdown = response.data
      // const dropdown = response.data.filter((x) => x.id !== user.id);
      // const searchDrop = dropdown?.filter((user) => {
      //   const hasMatch = user.name.toLowerCase().includes(inputDrop)
      //   return hasMatch;
      // })
      const options = dropdown.map((item) => ({
        value: item.id,
        label: item.name
      }))
      const sortoptions = options.sort((a, b) => a.label > b.label ? 1 : -1)
      setDropdown(sortoptions)
    } catch (error) {
    }
  };

  const fetchTpc = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.t_Pc}?page=${currentPageTpc}&limit=${recordsPerPageTpc}`);
      if (response) {
        setIsLoading(false)
      }
      const options = response.data.data.map((item) => ({
        value: item.id,
        label: item.name
      }))
      const sortoptions = options.sort((a, b) => a.label > b.label ? 1 : -1)
      setTpcList(sortoptions);
    } catch (error) {
    }
  };

  const fetchTsource = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.t_source}?page=${currentPageTsource}&limit=${recordsPerPageTsource}`);
      if (response) {
        setIsLoading(false)
      }
      const options = response.data.data.map((item) => ({
        value: item.id,
        label: item.name
      }))
      const sortoptions = options.sort((a, b) => a.label > b.label ? 1 : -1)
      setTsourceList(sortoptions);
    } catch (error) {
    }
  };

  const AddModalAddAcount = async (user) => {
    setShowPassword2({})
    setErrors('')
    dropDown(user)
    setAddUser(user.id)
    setNameForEditUser(user.name)
    setModalAddAcount(true);
    setTerminalid(null);
    setTerminalPassword(null);
    setTerminalPasswordReadOnly(null);
    setEntrydates("");
  };

  const AddmodalCommission = async (user) => {
    const response = await getMethod(`${Apiurl.accountcommistions}?terminalId=${user.terminal_id}`);
    const data = response.data;
    setCommission(data);
    setModalAddCommission(true);
    setSelectedMainUser(user);
  };

  const handledelete = async (id) => {
    const confirmation = await showConfirmationDialog("Are you sure you want to delete ?", "", "delete");
    if (confirmation) {
      const response = await deleteMethod(`${Apiurl.users}/${id}`);
      if (response.success) {
        Showdilog("Your Account is Deleted");
        setSearchInput("")
        fetchMainUsers()
        // setTimeout(() => {
        //   window.location.href = "/userdetails/bot";
        // }, 2000);
      } else {
        Showdilog(response.message, "failed");
      }
    }
  };

  const handleterminaldelete = async (terminalId) => {
    const confirmation = await showConfirmationDialog("Are you sure you want to delete ?", "", "delete");
    if (confirmation) {
      const response = await deleteMethod(`${Apiurl.terminal_delete}/${terminalId}`);
      if (response.success) {
        Showdilog("Your Account is Deleted");
        fetchMainUsers()
        setSelectedUser(null);
        // setTimeout(() => {
        //   window.location.href = "/userdetails/bot";
        // }, 2000);
      } else {
        Showdilog(response.message, "failed");
      }
    }
  };

  const handlecommisiondelete = async (e, terminalId) => {
    e.preventDefault();
    const confirmation = await showConfirmationDialog("Are you sure you want to delete", "", "delete");
    if (confirmation) {
      const response = await deleteMethod(`${Apiurl.commision}/${terminalId}`);
      if (response.success) {
        Showdilog("Commision deleted successfully");
        setModalAddCommission(false);
        // AddmodalCommission(selectedMainUser);
      } else {
        Showdilog(response.message, "failed");
      }
    }
  };
  const activepostion = (terminal_id) => { };

  const [userpopup, setUserpopup] = useState(false);
  const [filterWiseData, setFilteredData] = useState([]);
  const [forName, setForName] = useState([]);
  const [userDetailPopup, setUserDetailPopup] = useState(false);
  const [userDetail, SetUserDetail] = useState();

  const handlePopupOpen = (id) => {

    const filteredData = addmainusers.find((user) => user.user_mains.some(main => main.terminal_id === id));
    if (filteredData) {
      const filteredUserMains = filteredData.user_mains.filter(main => main.terminal_id === id);
      setFilteredData(filteredUserMains);
      setForName(filteredData)
    }
    setUserpopup(true);
  };

  const handleUserDetailPopup = (id) => {
    setUserDetailPopup(true);
    const UserDetail = addmainusers.find((user) => user.id === id);
    SetUserDetail(UserDetail)
    // const ibName = dropdown1.find((user) => user.id == UserDetail.user_mains.ib_id)

  }

  const fetchCurrency = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.currency}/get`);
      if (response) {
        setIsLoading(false)
      }
      setCurrecyType(response.data);
    } catch (error) {
    }
  };

  const addCurrency = (uid, tid) => {
    setModaAddCurrency(true)
    getCurrency(uid, tid)

    // fetchCurrency();
    setUidForCurrency(uid)
    setTidForCurrency(tid)
  };

  const getCurrency = async (uid, tid) => {
    // setModalEditCurrency(true);
    setUidForCurrency(uid)
    setTidForCurrency(tid)
    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.user_currency}/${tid}/${uid}`)
      if (response) {
        setIsLoading(false);
        if (response.data && response.data.length > 0) {
          const initialCountValues = response.data.map((currencyItem) => currencyItem.count);
          setCountValues(initialCountValues);
        } else {
          const zerosArray = currencyType.map(() => 0);
          setCountValues(zerosArray);
        }
      }
      setCurrencyData(response.data)
    } catch (error) {
      Showdilog("Error fetching user currency data", error)
      setIsLoading(false);
    }
  };

  const updateCountValue = (index, value) => {
    const newCountValues = [...countValues];
    newCountValues[index] = value;
    setCountValues(newCountValues);
  };

  const addCurrencySubmit = async (event) => {
    event.preventDefault();

    const isValidCount = countValues.every((countValue) => parseInt(countValue, 10) >= 0);
    if (!isValidCount) {
      console.error('Invalid count values. Please enter values >= 0.');
      return;
    }

    const allCurrencyData = {
      user_id: uidcurrency,
      terminal_id: tidcurrency,
      currency: currencyType?.map((currency, i) => ({
        id: currencyData[i]?.id !== "" ? currencyData[i]?.id : "",
        currencies_id: currency.id,
        count: parseInt(countValues[i], 10)
      }))
    }
    try {
      setIsLoading(true);
      const response = await postMethod(`${Apiurl.user_currency}/add_and_update`, allCurrencyData);
      if (response) {
        Showdilog(response.message, "success");
        setIsLoading(false);

      } else {
        Showdilog(response.message, "error");
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setModaAddCurrency(!modalAddCurrency)
    }
  };

  const handleFiles = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile)
  }

  const uploadFile = async (e) => {
    e.preventDefault()
    const formData = new FormData();
    formData.append('upload_files', file);
    formData.append('user_id', uidcurrency);
    formData.append('terminal_id', tidcurrency);
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.docs_upload_download, formData);
      if (response) {
        Showdilog(response.message, "success");
        setIsLoading(false);

      } else {
        Showdilog(response.message, "error");
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setModaUpload(!modalUpload)
    }
  };

  useEffect(() => {
    if (modalDownload) {
      downloadFilesData();
    }
  }, [modalDownload]);

  const downloadFilesData = async () => {

    try {
      setIsLoading(true);
      const response = await getMethod(`${Apiurl.docs_upload_download}/${tidcurrency}/${uidcurrency}`);
      if (response) {
        setIsLoading(false);
        setUploadedFiles(response.data)

      } else {
        Showdilog(response.message, "error");
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      setModalDownload(!modalUpload)
    }
  };

  const handledeleteFile = async (id) => {

    try {
      setIsLoading(true);
      const response = await deleteMethod(`${Apiurl.docs_upload_download}/${id}`);
      if (response) {
        setIsLoading(false);
        Showdilog(response.message, "success");
        downloadFilesData()

      } else {
        Showdilog(response.message, "error");
        setIsLoading(false);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };


  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const changeCPage = (id) => {
    setCurrentPage(id)
  }
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  const handleRecordsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setRecordsPerPage(newValue);
      setCurrentPage(1);
    }
  };

  const handleWhatsapp = () => {
    if (userDetail.contact_number)
      window.open(`http://wa.me/+91${userDetail.contact_number}`)
    else {
      showAlert("No contact number found")
    }
  }

  return (
    <>
      <Header />
      <Sidebar isSidebarOpen={props.isSidebarOpen} />
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>

        {/* terminal Detail popup */}

        {filterWiseData.length > 0 && (
          <Modal size="ig" isOpen={userpopup} toggle={() => setUserpopup(!userpopup)}>
            <ModalHeader toggle={() => setUserpopup(!userpopup)}>
              {
                <form
                  style={{ margin: "10px 70px" }}
                  className="login">
                  <div className="box" style={{}}>
                    <label style={{ fontSize: "16px", display: "block" }}>
                      User ID : {forName.name}
                    </label>
                  </div>
                  <div style={{}}>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        Terminal ID : {filterWiseData[0]?.terminal_id}
                      </label>
                    </div>
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label>
                      Terminal_Pass : {filterWiseData[0].terminal_pass}
                    </label>

                  </div>
                  <div style={{ marginBottom: "6px" }}>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}> Entrydate  : {filterWiseData[0].entrydate}</label>
                    </div>
                  </div>
                  <div>
                    <div className="box  d-flex flex-column">
                      <label style={{ fontSize: "16px" }}>Floating  : {filterWiseData[0].floating}</label>

                    </div>

                  </div>
                  <div>
                    <div className="box  d-flex flex-column">
                      <label style={{ fontSize: "16px" }}>Account Balance  : {filterWiseData[0].account_balance}</label>

                    </div>

                  </div>
                  <div>
                    <div className="box  d-flex flex-column">
                      <label style={{ fontSize: "16px" }}>Expected Profit  : {filterWiseData[0].expected_profit}</label>

                    </div>

                  </div>
                  <div>
                    <div className="box  d-flex flex-column">
                      <label style={{ fontSize: "16px" }}>Equity Remaining  :{filterWiseData[0].equity_remaining}</label>

                    </div>

                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        T Source  :  {filterWiseData[0].t_source_id}
                      </label>

                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        T Pc  :         {filterWiseData[0].t_pc_id}
                      </label>

                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        T Status  :   {filterWiseData[0].t_status}
                      </label>

                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        Current Daterange  : {filterWiseData[0].currunt_daterange}
                      </label>

                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        Current Profit  : {filterWiseData[0].currunt_profit}
                      </label>

                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <label style={{ fontSize: "16px" }}>
                        IB Id  : {filterWiseData[0].ib_id}
                      </label>

                    </div>
                  </div>

                  <div className="modal-footer d-flex justify-content-between">
                    <button
                      type="button"
                      className="btn btn-secondary "
                      data-bs-dismiss="modal"
                      onClick={() => setUserpopup(!userpopup)}> Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <span className="loader" style={{ zIndex: "10000" }}></span>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>


              }
            </ModalHeader>
          </Modal>
        )}

        {/* user Detail popup */}

        <Modal size="xl" isOpen={userDetailPopup} toggle={() => setUserDetailPopup(!userDetailPopup)} style={{ zIndex: "999999999999" }}>
          <ModalHeader toggle={() => setUserDetailPopup(!userDetailPopup)}>
            {userDetail &&
              <div>
                <div className="d-flex justify-content-center">
                  <h4>User Name : {userDetail.name}</h4>
                </div>
                <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                  <table className="usertable" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Email</th>
                        <th>Password</th>
                        <th>Role</th>
                        <th>Note</th>
                        <th>Entrydate</th>
                        <th>Bank Email</th>
                        <th>Bank Password</th>
                        <th>Contact Number</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userDetail.id}</td>
                        <td>{userDetail.email}</td>
                        <td>{userDetail.password}</td>
                        <td>{userDetail.role}</td>
                        <td>{userDetail.note}</td>
                        <td>{userDetail.entrydate}</td>
                        <td>{userDetail.bank_email}</td>
                        <td>{userDetail.bank_password}</td>
                        <td>{userDetail.contact_number}</td>
                        <td>{userDetail.status}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <h5>Terminals</h5>
                </div>
                <div className="modal-body" style={{ maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}>
                  <table className="usertable" style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>Terminal id</th>
                        <th>Terminal Password</th>
                        <th>IB Id</th>
                        <th>t_pc</th>
                        <th>t_source</th>
                        <th>t_status</th>
                        <th>account_balance </th>
                        <th>current_daterange </th>
                        <th>current_profit </th>
                        <th>equity_remaining</th>
                        <th>expected_profit</th>
                        <th>floating</th>
                        <th>status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {userDetail.user_mains.map((user) => (
                        <tr>
                          <td>{user.terminal_id}</td>
                          <td>{user.terminal_pass}</td>
                          <td>{user.ib_id !== 0 || null ? user.ib_id : ""} - {user?.user?.name}</td>
                          <td>{user.t_pc_id !== 0 || null ? user.t_pc_id : " "}-{user?.t_pc?.name}</td>
                          <td>{user.t_source_id !== 0 || null ? user.t_source_id : " "}-{user?.t_source?.name}</td>
                          <td>{user.t_status}</td>
                          <td>{user.account_balance}</td>
                          <td>{user.currunt_daterange}</td>
                          <td>{user.currunt_profit}</td>
                          <td>{user.equity_remaining}</td>
                          <td>{user.expected_profit}</td>
                          <td>{user.floating}</td>
                          <td>{user.status}</td>
                        </tr>
                      ))

                      }
                    </tbody>
                  </table>
                </div>


                <div className="modal-footer d-flex justify-content-center">
                  <button type="button" className="btn btn-secondary " data-bs-dismiss="modal"
                    onClick={() => setUserDetailPopup(!userDetailPopup)}> Close
                  </button>
                  <button type="button" className="btn btn-primary " data-bs-dismiss="modal"
                    onClick={() => setUserDetailPopup(!userDetailPopup)}> Download
                  </button>
                  <button type="button" className="btn btn-success " data-bs-dismiss="modal"
                    onClick={handleWhatsapp}>  <i class="fa fa-whatsapp fa-lg me-1" aria-hidden="true"></i> WhatsApp
                  </button>
                </div>
              </div>

            }
          </ModalHeader>
        </Modal>

        {/* add user popup */}

        <Modal size="" isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>
            <form style={{ margin: "10px 70px" }} className="login" onSubmit={handleSubmit}>
              <div className="box" style={{}}>
                <label style={{ fontSize: "15px", marginBottom: "0px" }}>
                  User Name <span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="input rounded"
                  value={name}
                  placeholder="Enter your username"
                  onChange={(event) => handlechange("name", event)}
                  style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                {errors.name && (
                  <p style={{ fontSize: "12px", marginBottom: "0px" }} className="errorMsg text-danger text-bold">{errors.name}</p>
                )}
              </div>
              <div style={{}}>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>
                    Email <span className="text-danger" style={{ margin: "4px" }}> *</span>
                  </label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(event) => handlechange("email", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }}
                  />
                  {errors.email && (
                    <p
                      style={{ fontSize: "12px", marginBottom: "0px" }}
                      className="errorMsg text-danger text-bold">{errors.email}
                    </p>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label style={{ fontSize: "15px", marginBottom: "0px" }}>
                  Password<span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                <div className="position-relative">
                  <input
                    type={!showPassword ? "text" : "password"}
                    className="input rounded"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(event) => handlechange("password", event)}
                    style={{
                      width: "100%",
                      height: "35px",
                      fontSize: "14px",
                      paddingRight: "40px",
                    }}
                  />
                  {errors.password && (
                    <p className="errorMsg text-danger text-bold" style={{ fontSize: "12px", marginBottom: "0px" }}>
                      {errors.password}</p>
                  )}
                  <span
                    className="password-toggle-icon  "

                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ? (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </span>
                </div>

              </div>
              <div style={{ marginBottom: "6px" }}>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>Note</label>
                  <input
                    type="text"
                    placeholder="Enter your note"
                    value={note}
                    onChange={(event) => handlechange("note", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }}
                  />
                </div>
              </div>
              <div>
                <div className="box  d-flex flex-column">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>Entry Date</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={entrydate}
                    className="input rounded w-100 py-1 fs-6"
                    onChange={(entrydate) => setEntrydate(entrydate)}
                    placeholderText="Select Date" />
                  {errors.entrydate && (
                    <p className="errorMsg text-danger text-bold" style={{ fontSize: "12px", marginBottom: "0px" }}>
                      {errors.entrydate}</p>
                  )}
                </div>
              </div>

              <div style={{}}>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}> Bank Email</label>
                  <input
                    type="email"
                    placeholder="Enter your bank email"
                    value={bankemail}
                    onChange={(event) => handlechange("bankemail", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                </div>
              </div>

              <div style={{}}>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>Bank Password</label>
                  <input
                    type="password"
                    placeholder="Enter your bank password"
                    value={bankpassword}
                    onChange={(event) => handlechange("bankpassword", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                </div>
              </div>

              <div style={{}}>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>Contact Number</label>
                  <input
                    type="text"
                    placeholder="Enter your contact number"
                    value={contactnumber}
                    onChange={(event) => handlechange("contactnumber", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                </div>
              </div>

              <div>
                <div className="box">
                  <label style={{ fontSize: "15px", marginBottom: "0px" }}>Role<span className="text-danger" style={{ margin: "4px" }}>*</span>
                  </label>
                  <select
                    className="input rounded"
                    value={role}
                    onChange={(event) => handlechange("role", event)}
                    style={{ width: "100%", height: "35px", fontSize: "14px" }}>
                    <option value="">Select Role</option>
                    {userEmail1 === "superadmin@gmail.com" ? (
                      <>
                        <option value="admin">Admin</option>
                        <option value="user">User</option>
                        <option value="IB">IB</option>
                      </>
                    ) : (
                      <></>
                    )}
                    {userEmail === "Admin" && (
                      <>
                        <option value="user">User</option>
                        <option value="IB">IB</option>
                      </>
                    )}
                  </select>
                  {errors.role && (
                    <p
                      style={{ fontSize: "12px", marginBottom: "0px" }} className="errorMsg text-danger text-bold">{errors.role}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setModal(!modal)}>Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}>
                  {isLoading ? (<span className="loader" style={{ zIndex: "10000" }}></span>) : ("Submit")}
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>

        <div className="container-fluid pt-4 ps-3" style={{ marginTop: "-20px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>


                    {userEmail1 === "superadmin@gmail.com" ||
                      userEmail === "Admin" ? (
                      <>
                        <h6 className="text-white text-capitalize ps-3">
                          <li className="nav-item d-flex align-items-center">
                            <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                              <div className="input-group input-group-outline mt-2">
                                <input
                                  type="text"
                                  className="form-control text-white"
                                  placeholder="Search here...."
                                  value={searchInput}
                                  onChange={handleSearch}
                                />
                              </div>
                            </div>
                          </li>
                        </h6>
                        <li className="nav-item d-flex align-items-center"> 
                          <button
                            onClick={handleSetmodal}
                            className="btn btn-outline-dark d-flex mt-3 me-3 ms-1"
                            style={{ color: "white", border: "1px solid white",whiteSpace:'nowrap' }}
                          >
                            <i className="fa fa-user-plus fa-lg mt-1 me-1" style={{ color: "white" }} aria-hidden="true"></i>
                            Add User
                          </button>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    {
                      userEmail1 === `superadmin@gmail.com` ||
                        userEmail === "Admin" ? (
                        <>
                          <table
                            className="table align-items-center mb-0"
                            style={{ width: "100%" }}>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  Id
                                </th>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  Name
                                </th>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  Email
                                </th>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder text-center">
                                  Actions
                                </th>
                              </tr>
                            </thead>
                            <tbody className="table align-items-center">
                              {
                                isLoading ? (
                                  <tr>
                                    <td colSpan="6" className="text-center">
                                      <span className="loader"></span>
                                    </td>
                                  </tr>
                                ) : (

                                  addmainusers?.map((user, i) => (
                                    <React.Fragment key={user.id}>
                                      <tr>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleRowClick(user)}>
                                          <i
                                            className={`fa ${selectedUser && selectedUser[0]?.id === user.id ? "fa-arrow-down" : "fa-arrow-right"}`}
                                            aria-hidden="true"
                                          />
                                        </td>
                                        <td>
                                          <h6 className="mb-0 ms-2">{user.id}</h6>
                                        </td>
                                        <td >
                                          <h6 className=" font-weight-bold mb-0 ms-2">{user.name}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" font-weight-bold mb-0 ms-2">{user.email}</h6>
                                        </td>
                                        <td>
                                          <span
                                            title="View info"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal">
                                            <i
                                              className="fa fa-info-circle fa-lg mt-2 ms-4"
                                              onClick={() => handleUserDetailPopup(user.id)}
                                              style={{ color: "#fb8c00bd", cursor: "pointer" }}
                                              aria-hidden="true">
                                            </i>
                                          </span>
                                          <span
                                            title="Add Account"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal">
                                            <i
                                              className="fa fa-plus-circle fa-lg mt-2 ms-4"
                                              onClick={() => {
                                                AddModalAddAcount(user)
                                                fetchMainUsers(user)
                                              }}
                                              style={{ color: "green", cursor: "pointer" }}
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span
                                            title="Edit"
                                            data-bs-toggle="modal"
                                            data-bs-target="#exampleModal"
                                            style={{ color: "green", cursor: "pointer" }}
                                            onClick={() => useredite(user.id)}>
                                            <i
                                              className="fa fa-pencil-square-o fa-lg ms-4"
                                              aria-hidden="true">
                                            </i>
                                          </span>
                                          <span
                                            title="Delete"
                                            style={{ color: "red", cursor: "pointer" }}
                                            onClick={() => handledelete(user.id)}>
                                            <i
                                              className="fa fa-trash fa-lg mt-2 ms-4"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </td>
                                      </tr>
                                      {
                                        selectedUser &&
                                        selectedUser[0].id === user.id && (
                                          <>
                                            <tr>
                                              <td colSpan={6}>
                                                <table
                                                  className="table table-striped table-bordered "
                                                  style={{ width: "100px" }}>
                                                  <thead>
                                                    <tr>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        User Name
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Terminal Id
                                                      </th>
                                                      <th
                                                        colSpan={2}
                                                        className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Terminal Password
                                                      </th>
                                                      <th style={{ borderColor: "white" }}></th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Terminal Password Only Read
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Entry Date
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        T PC
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Commission
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Currency
                                                      </th>
                                                      <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Actions
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {selectedUser[0] && selectedUser[0]?.user_mains?.map((list) => (
                                                      <tr key={list.terminal_id}>
                                                        <td onClick={() => handlePopupOpen(list.terminal_id)} style={{ cursor: "pointer" }}>
                                                          <h6 className=" font-weight-bold mb-0 ms-3">{type === 'Ib' ? list.user.name : selectedUser[0]?.name}</h6>
                                                        </td>
                                                        <td>
                                                          <NavLink
                                                            to={`/activeposition/${list.terminal_id}`}
                                                            target="_blank">
                                                            <h6
                                                              className=" font-weight-bold mb-0 ms-3"
                                                              style={{
                                                                cursor: "pointer",
                                                                color: getStatusColor(list.t_status)
                                                              }}
                                                              onClick={() => activepostion(list.terminal_id)}>
                                                              {list?.terminal_id}
                                                            </h6>
                                                          </NavLink>
                                                        </td>
                                                        <td colSpan={2}>
                                                          <h6 className="font-weight-bolder mb-0 ms-3">
                                                            {showPassword2[list.terminal_id]
                                                              ? list.terminal_pass
                                                              : list.terminal_pass !== undefined
                                                                ? list.terminal_pass.replace(/.(?=.{0})/g, "*") : ""}
                                                          </h6>
                                                        </td>
                                                        <td className="ms-n5">
                                                          <i
                                                            className={`fa ${showPassword2[list.terminal_id] ? "fa-eye-slash" : "fa-eye"} password-icon`}
                                                            onClick={() => handlePasswordClick2(list.terminal_id)} />
                                                        </td>
                                                        <td>
                                                          <h6 className="font-weight-bolder mb-0 ms-4">
                                                            {list.terminal_read_only_password}
                                                          </h6>
                                                        </td>

                                                        <td className="d-flex flex-column justify-content-center">
                                                          <h6 className=" font-weight-bold mb-0 ms-2">
                                                            {new Date(list.entrydate).toLocaleDateString("en-GB")}{" "}
                                                            {/* {new Date(list.entrydate).toLocaleTimeString("en-US" )} */}
                                                          </h6>
                                                        </td>

                                                        <td>
                                                          <h6 className="font-weight-bolder mb-0 ms-3 "> {list.t_pc_id}</h6>
                                                        </td>
                                                        <td>
                                                          <span
                                                            title="Add commision"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal">
                                                            <i
                                                              className="fa fa-plus-circle fa-lg mt-2 me-2"
                                                              onClick={() => AddmodalCommission(list)}
                                                              style={{ color: "green", cursor: "pointer", marginLeft: "33px" }} aria-hidden="true">
                                                            </i>
                                                          </span>
                                                        </td>
                                                        <td>
                                                          <span
                                                            title="Add Currency"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            style={{ color: "green", cursor: "pointer" }}
                                                            onClick={() => addCurrency(user?.id, list?.terminal_id)}
                                                          >
                                                            <i
                                                              className="fa fa-sharp fa-regular fa-coins fa-lg mt-2 ms-4"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          {/* <span
                                                          title="Edit Currency"
                                                          data-bs-toggle="modal"
                                                          data-bs-target="#exampleModal"
                                                          style={{ color: "green", cursor: "pointer" }}
                                                          onClick={() => editCurrency(user?.id, list?.terminal_id)}
                                                        >
                                                          <i className="fa fa-pencil fa-lg mt-2" aria-hidden="true"></i>
                                                        </span> */}
                                                        </td>

                                                        <td>
                                                          <span
                                                            title="Add file"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            style={{ color: "orange", cursor: "pointer" }}
                                                            onClick={() => FileUploadModal(user?.id, list?.terminal_id)}
                                                          >
                                                            <i
                                                              className="fa fa-upload fa-lg mt-2 me-2 me-4"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          <span
                                                            title="Download file"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            style={{ color: "red", cursor: "pointer" }}
                                                            onClick={() => FileDownloadModal(user?.id, list?.terminal_id)}
                                                          >
                                                            <i
                                                              className="fa fa-download fa-lg mt-2 me-2 me-4"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          <span
                                                            title="Edit terminal"
                                                            data-bs-toggle="modal"
                                                            data-bs-target="#exampleModal"
                                                            style={{ color: "green", cursor: "pointer" }}
                                                            onClick={() => AddModalAddAcount1(list?.terminal_id)}
                                                          >
                                                            <i
                                                              className="fa fa-pencil-square-o fa-lg mt-2 me-2 me-4"
                                                              aria-hidden="true"
                                                            ></i>
                                                          </span>
                                                          <span
                                                            title="Delete"
                                                            style={{ color: "red", cursor: "pointer" }}
                                                            onClick={() => handleterminaldelete(list.terminal_id)}>
                                                            <i
                                                              className="fa fa-trash fa-lg mt-2"
                                                              aria-hidden="true">
                                                            </i>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                    </React.Fragment>
                                  ))
                                )}
                            </tbody>
                          </table>

                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="" style={{ border: '', padding: '5px', borderRadius: '5px', marginTop: '45px' }}>
                              <label htmlFor="recordsPerPage">Records per Page:</label>
                              <select
                                id="recordsPerPage"
                                value={recordsPerPage}
                                onChange={handleRecordsPerPageChange}>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                              </select>
                            </div>

                            <nav className="mt-5 me-3 d-flex justify-content-end">
                              <ul className="pagination">
                                <li className="page-item">
                                  <a href="#" className="page-link" onClick={prePage}>prev</a>
                                </li>

                                {
                                  numbers.map((n, i) => (
                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                      <a href="#" className="page-link" onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  ))
                                }

                                <li className={`page-item ${currentPage === totalPage ? 'disabled' : ''}`}>
                                  <a href="#" className="page-link" onClick={nextPage}>next</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </>
                      ) : (
                        <table
                          className="table align-items-center mb-0"
                          style={{ width: "100%" }}>
                          <thead>
                            <tr>
                              <th>#</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Id
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Email
                              </th>

                              <th className="text-secondary opacity-7"></th>
                            </tr>
                          </thead>
                          <tbody className="table align-items-center">
                            {isLoading ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span className="loader"></span>
                                </td>
                              </tr>
                            ) : (
                              addmainusers.map((user, i) => (
                                <React.Fragment key={user.id}>
                                  <tr>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleRowClick(user, i, user.id)}>
                                      <i
                                        className={`fa ${selectedUser && selectedUser[0]?.id === user.id ? "fa-arrow-down" : "fa-arrow-right"}`}
                                        aria-hidden="true"
                                      />
                                    </td>
                                    <td>
                                      <h6 className="mb-0">{user.id}</h6>
                                    </td>
                                    <td>
                                      <h6 className=" font-weight-bold mb-0">
                                        {user.name}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className=" font-weight-bold mb-0">
                                        {user.email}
                                      </h6>
                                    </td>
                                  </tr>
                                  {selectedUser &&
                                    selectedUser[0].id === user.id && (
                                      <>
                                        <tr>
                                          <td colSpan={6}>
                                            <table
                                              className="table table-striped table-bordered "
                                              style={{ width: "100px" }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                    User Name
                                                  </th>
                                                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                    Terminal Id
                                                  </th>

                                                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                    Entry Date
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {selectedUser[0].user_mains.map((mainuser, i) => (
                                                  <tr key={mainuser.id}>
                                                    <td onClick={() => handlePopupOpen(mainuser.terminal_id)} style={{ cursor: "pointer" }}>
                                                      <h6 className=" font-weight-bold mb-0">
                                                        {user.name}
                                                      </h6>
                                                    </td>
                                                    <td>
                                                      <NavLink
                                                        to={`/activeposition/${mainuser.terminal_id}`}
                                                        target="_blank">
                                                        <h6
                                                          className=" font-weight-bold mb-0"
                                                          style={{ cursor: "pointer", marginLeft: "-20px", color: getStatusColor(mainuser.t_status) }}
                                                          onClick={() => activepostion(mainuser.terminal_id)}>
                                                          {mainuser.terminal_id}
                                                        </h6>
                                                      </NavLink>
                                                    </td>

                                                    <td className="d-flex flex-column justify-content-center">
                                                      <h6 className=" font-weight-bold mb-0">
                                                        {new Date(mainuser.entrydate).toLocaleDateString("en-GB")}{" "}
                                                      </h6>
                                                    </td>
                                                  </tr>
                                                )
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                </React.Fragment>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* add terminal popup */}

      {modalAddAcount ? (
        <Modal
          size="ig"
          isOpen={modalAddAcount}
          toggle={() => setModalAddAcount(!modalAddAcount)}
        >
          <ModalHeader toggle={() => setModalAddAcount(!modalAddAcount)}>
            <form
              style={{ marginTop: "30px" }}
              className="login"
              onSubmit={(event) => AddUserMainhandlesubmit(event)}
            >
              <div className="box d-flex gap-3 align-items-center flex-row mb-2">
                <label style={{ fontSize: "15px", fontStyle: "bolder", marginBottom: "1px", width: "140px", whiteSpace: "nowrap" }}>User Name
                </label>
                <input
                  type="text"
                  className="input rounded"
                  value={nameForEditUser}
                  onChange={(event) => addacounthandlechange("username", event)}
                  style={{ width: "100%", height: "35px", fontSize: "15px" }}
                />
              </div>

              <div className="box d-flex  gap-3 align-items-center flex-row mb-2">
                <label style={{ fontSize: "15px", marginBottom: "1px", width: "140px" }}> ID
                  <span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>
                <div className="d-flex flex-column" style={{ width: "100%" }}>
                  <input
                    type="text"
                    placeholder="Enter your Id"
                    value={terminalid}
                    onChange={(event) => addacounthandlechange("terminalid", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "15px", }} />
                  {errors.terminalid && (
                    <p
                      style={{ fontSize: "12px" }}
                      className="errorMsg text-danger text-bold mb-0">{errors.terminalid}
                    </p>
                  )}
                </div>

              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "15px", marginBottom: "2px", width: "140px", whiteSpace: "nowrap" }}>T Password
                  <span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>
                <div className="w-100 position-relative">
                  <input
                    type={showPassword2 ? "password" : "text"}
                    className="input rounded"
                    placeholder="Enter your Terminal Password"
                    value={terminalpassword}
                    onChange={(event) => addacounthandlechange("terminalpassword", event)}
                    style={{ width: "100%", height: "35px", fontSize: "14px" }}
                  />

                  {errors.terminalpassword && (
                    <p
                      style={{ marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold"
                    >
                      {errors.terminalpassword}
                    </p>
                  )}
                  <span
                    className="password-toggle-icon "

                    onClick={() => setShowPassword2(!showPassword2)}
                  >
                    {showPassword2 ? (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </span>
                </div>
              </div>

              <div className="box d-flex align-items-center gap-3 flex-row mb-2">
                <label style={{ fontSize: "15px", marginBottom: "1px", width: "140px", whiteSpace: "nowrap" }}>
                  R Password
                  <span className="text-danger"> * </span>{" "}
                </label>
                <div className="d-flex flex-column w-100">
                  <input
                    type="text"
                    placeholder="Enter your Terminal Confirm Password"
                    value={terminalpasswordreadonly}
                    onChange={(event) => addacounthandlechange("terminalpasswordreadonly", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                  {errors.terminalpasswordreadonly && (
                    <p
                      style={{ marginLeft: "5px", marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold">{errors.terminalpasswordreadonly}
                    </p>
                  )}
                </div>
              </div>

              <div className="box d-flex align-items-center gap-3 flex-row mb-2">
                <label style={{ fontSize: "15px", marginBottom: "1px", width: "140px", whiteSpace: "nowrap" }}>E Date
                  <span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                <div style={{ width: "100%", display: "block" }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={entrydates}
                    className="input rounded datepicker"
                    placeholderText="Select Entrydate"
                    style={{ width: "100%", height: "35px", fontSize: "14px" }}
                    onChange={(date) => setEntrydates(date)}
                  />
                  {errors.entrydates && (
                    <p
                      style={{ marginLeft: "5px", marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold">{errors.entrydates}
                    </p>
                  )}
                </div>

                {/* {errors.entrydates && (
                  <p
                    style={{ marginBottom: "3px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.entrydates}
                  </p>
                )} */}
              </div>
              {/* 
                  <div className="form-group position-relative">
                    <label>
                      T Source
                    </label>
                    <input
                      type="text"
                      className="input rounded"
                      placeholder="Enter your T source"
                      value={tsource}
                      onChange={(event) => addacounthandlechange("tsource", event)}
                      style={{ width: "100%", height: "40px", fontSize: "16px" }}
                    />

                    {errors.tsource && (
                  <p
                    style={{ marginBottom: "3px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.tsource}
                  </p>
                )}
                  </div> */}

              {/* <div className="form-group position-relative">
                    <label>T Pc</label>
                    <input
                      type="text"
                      className="input rounded"
                      placeholder="Enter your T Pc"
                      value={tpc}
                      onChange={(event) => addacounthandlechange("tpc", event)}
                      style={{ width: "100%", height: "40px", fontSize: "16px" }}
                    />

                    {errors.tpc && (
                  <p
                    style={{ marginBottom: "3px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.tpc}
                  </p>
                )}
                  </div> */}


              {/* <div className="box " style={{ marginBottom: "6px" }}>
                  <label
                    style={{ fontSize: "16px", display: "block", fontStyle: "bolder" }}> T Pc</label>
                  <select
                    value={tpc}
                    className="input rounded"
                    onChange={(event) => addacounthandlechange("tpc", event)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  >
                    <option value="">Select T Pc</option>
                    {tpclist.sort((a, b) => a.name > b.name ? 1 : -1).map((user, index) => (
                      <option key={index} value={user.id}>{user.name}</option>
                    ))}
                  </select>
                </div> */}

              {/* <div className="box " style={{ marginBottom: "6px" }}>
                  <label for="tpclist" class="form-label">Choose your browser from the list:</label>
                  <input className="input rounded" list="tpclist" name="tpclist" id="tpclist"
                    onChange={(event) => addacounthandlechange("tpc", event)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }} placeholder="search T Pc" />
                  <datalist id="tpclist">
                    {tpclist.sort((a, b) => a.name > b.name ? 1 : -1).map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </datalist>
                </div> */}

              {/* <div className="box " style={{ marginBottom: "6px" }}>
                  <label
                    style={{ fontSize: "16px", display: "block", fontStyle: "bolder" }}> T Source</label>
                  <select
                    value={tsource}
                    className="input rounded"
                    onChange={(event) => addacounthandlechange("tsource", event)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  >
                    <option value="">Select T Source</option>
                    {tsourcelist.sort((a, b) => a.name > b.name ? 1 : -1).map((user, index) => (
                      <option key={index} value={user.id}>{user.name}</option>
                    ))}
                  </select>
                </div> */}



              {/* <div className="box " style={{ marginBottom: "6px" }}>
                  <label style={{ fontSize: "16px", display: "block", fontStyle: "bolder" }}>IB</label>
                  <select
                    value={iblist}
                    className="input rounded"
                    onChange={handleSelectIB}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  >
                    <option value="">Select IB </option>
                    {dropdown.map((user, index) => (
                      <option key={index} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                </div> */}

              {/* <label for="browser" class="form-label">Choose your browser from the list:</label>
                <input className="" list="browsers" name="browser" id="browser" placeholder="search" />
                <datalist id="browsers">
                  {dropdown.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </datalist> */}

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "16px", marginBottom: "1px", width: "140px", whiteSpace: "nowrap" }}>V S
                  {/* <span className="text-danger" style={{ margin: "4px" }}>*</span> */}
                  </label>
                <div style={{ width: "100%" }}>
                  <Select
                    onChange={(tpc) => setTpc(tpc.value)}
                    options={tpclist}
                    placeholder="search T pc..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
             
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "15px", marginBottom: "1px", width: "140px", whiteSpace: "nowrap" }}>T S
                  {/* <span className="text-danger" style={{ margin: "4px" }}>*</span> */}
                  </label>
                <div style={{ width: "100%" }}>
                  <Select
                    onChange={(tsource) => setTsource(tsource.value)}
                    options={tsourcelist}
                    placeholder="search T source..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
            
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "16px", width: "140px", whiteSpace: "nowrap" }}>T Status
                  {/* <span className="text-danger" style={{ margin: "4px" }}>*</span> */}
                </label>

                <select
                  className="input rounded"
                  value={tstatus}
                  onChange={(e) => setTStatus(e.target.value)}
                  style={{ width: "100%", height: "40px", fontSize: "16px", border: '2px solid #ab9e9e99' }}>
                  <option value="">Select Status</option>
                  <>
                    <option value="active">active</option>
                    <option value="stop">stop</option>
                    <option value="deleted">delete</option>
                    <option value="need to set bot">need to set bot</option>
                  </>
                </select>
                {/* {errors.tstatus && (
                    <p
                      style={{ marginBottom: "3px" }}
                      className="errorMsg text-danger text-bold"
                    >
                      {errors.tstatus}
                    </p>
                  )} */}
              </div>

              <div className="box d-flex align-items-center flex-row gap-3">
                <label style={{ fontSize: "15px", width: "140px", whiteSpace: "nowrap" }}>IB ID</label>
                <div style={{ width: "100%" }}>
                  <Select
                    onChange={(iblist) => setIbList(iblist.value)}
                    options={dropdown1}
                    placeholder="search IB Id..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
                </div>
              </div>


              <div className="modal-footer d-flex " style={{ justifyContent: 'center' }}>
                <button
                  type="button"
                  className="btn btn-secondary me-5"
                  data-bs-dismiss="modal"
                  onClick={() => setModalAddAcount(!modalAddAcount)}>
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary ms-5"
                  disabled={isLoading}>
                  {isLoading ? (
                    <i className="fa fa-spinner fa-spin"></i>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null
      }

      {/* add commision popup */}

      {addmainusers.length > 0 && ModalAddCommission ? (
        <Modal
          size="ig"
          isOpen={ModalAddCommission}
          toggle={() => setModalAddCommission(!ModalAddCommission)}>
          <ModalHeader toggle={() => setModalAddCommission(!ModalAddCommission)}>
            <form
              style={{ margin: "10px 70px" }}
              className="login"
              onSubmit={(event) => AddCommisionsubmit(event)}>
              {/* <div className="box " style={{ marginBottom: "6px" }}>
                <label style={{ fontSize: "16px", display: "block", fontStyle: "bolder" }}>
                  User Name
                  <span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>

                <select
                  value={selectedUserName}
                  className="input rounded"
                  onChange={(event) => setSelectedUserName(event.target.value)}
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}>
                  <option value="">Select User</option>
                  {dropdown1.map((user, index) => (
                    <option key={index} value={user.value}>
                      {user.label}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="box " style={{ marginBottom: "6px", width: "100%" }}>
                <label style={{ fontSize: "16px", display: "block", fontStyle: "bolder" }}>User Name</label>
                <Select
                  onChange={(user) => setSelectedUserName(user.value)}
                  options={dropdown1}
                  placeholder="search User..."
                  styles={{
                    placeholder: (baseStyles, state) => ({
                      ...baseStyles,
                      fontWeight: 400,
                      fontSize: '16px'
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      fontSize: '15px',
                      padding: '4px',
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: '2px solid black',
                      borderRadius: '7px',
                    }),
                  }}
                />
                {errors.selectedUserName && (
                    <p
                      style={{ marginBottom: "3px" }}
                      className="errorMsg text-danger text-bold">
                      {errors.selectedUserName}
                    </p>
                  )}
              </div>

              <div style={{ marginBottom: "6px" }}>
                <div className="box">
                  <label style={{ fontSize: "16px" }}>Sharing Percentage
                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter your Percentage"
                    value={sharingpercentage}
                    onChange={(event) => addcommisionhandlechange("sharingpercentage", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "40px", fontSize: "16px" }} />

                  {sharingpercentage > 100 && (
                    <p style={{ fontSize: "12px", color: "red" }}>Sharing percentage cannot be greater than 100</p>
                  )}
                  {errors.sharingpercentage && (
                    <p
                      style={{ marginBottom: "3px" }}
                      className="errorMsg text-danger text-bold">
                      {errors.sharingpercentage}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ marginBottom: "6px" }}>
                <div className="box">
                  <label style={{ fontSize: "16px" }}>Note</label>
                  <input
                    type="text"
                    placeholder="Enter your Note"
                    value={commisionnote}
                    onChange={(event) => addcommisionhandlechange("commisionnote", event)}
                    className="input rounded"
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  />
                </div>
              </div>

              <div style={{ marginBottom: "6px" }}>
                <div className="box  d-flex flex-column">
                  <label style={{ fontSize: "16px" }}>Entry Date</label>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={commissionentrydate}
                    placeholder="Select Entrydate"
                    className="input rounded w-100 py-2 fs-6"
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                    placeholderText="Select Entrydate"
                    onChange={(commissionentrydate) => setCommissionEntrydate(commissionentrydate)} />
                </div>
              </div>

              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setModalAddCommission(!ModalAddCommission)}>Close
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}>
                  {isLoading ? (<i className="fa fa-spinner fa-spin"></i>) : ("Submit")}
                </button>
              </div>
            </form>
            {
              <>
                {/* mini table in the commision pop-up  */}

                <tr className="d-flex justify-content-center w-100">
                  <td colSpan={2}>
                  <table
                      className="table table-striped table-bordered "
                      style={{ width: "100px" }}
                    >
                      <thead>
                        <tr>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            User Name
                          </th>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Percentage
                          </th>
                          <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {usercommission.map((commission, i) => (
                          <tr key={i}>
                            <td>
                              <h6 className="font-weight-bold mb-0 ms-4">
                                {commission.user.name.length > 10 ? commission.user.name.substring(0, 15) + "..." : commission.user.name}
                              </h6>
                            </td>
                            <td>
                              <h6 className=" font-weight-bold mb-0 ms-5">
                                {commission.sharing_per}
                              </h6>
                            </td>

                            {commission.user_id !== commission.user_main.user_id && (
                              <td>
                                <span
                                  title="Delete"
                                  style={{ color: "red", height: "10px" }}
                                  onClick={(e) => handlecommisiondelete(e, commission.id)}>
                                  <i className="fa fa-trash fa-lg mt-2 me-2 ms-4" aria-hidden="true"></i>
                                </span>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            }
          </ModalHeader>
        </Modal>
      ) : null}

      {/* terminal edit modal */}

      {addmainusers.length > 0 && modalEdit ? (
        <Modal
          size="ig"
          isOpen={modalEdit}
          toggle={() => setModalEdit(!modalEdit)}
        >
          <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
            <form
              style={{ marginTop: "30px" }}
              className="login"
              onSubmit={(event) => AddUserMainhandlesubmit1(event, terminalid)}
            >
              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <div style={{ width: "140px", whiteSpace: "nowrap" }}>
                  <label style={{ fontSize: "15px", marginBottom: "1px" }}>ID
                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                  </label>
                </div>
                <div className="d-flex flex-column w-100">
                  <input
                    type="text"
                    placeholder="Enter your Terminal ID"
                    value={terminalid}
                    onChange={(e) => setTerminalid(e.target.value)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "15px", }} />
                  {errors.terminalid && (
                    <p
                      style={{ marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold">{errors.terminalid}
                    </p>
                  )}
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <div style={{ width: "140px", whiteSpace: "nowrap" }}>
                  <label style={{ fontSize: "15px", marginBottom: "2px" }}>T Password
                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                  </label>
                </div>
                <div className="position-relative" style={{ width: "100%" }}>
                  <input
                    type={showPassword2 ? "password" : "text"}
                    className="input rounded"
                    placeholder="Enter your Terminal Password"
                    value={terminalpassword}
                    onChange={(e) => setTerminalPassword(e.target.value)}
                    style={{ width: "100%", height: "35px", fontSize: "14px" }} />
                  {errors.terminalpassword && (
                    <p
                      style={{ marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold"
                    >
                      {errors.terminalpassword}
                    </p>
                  )}
                  <span
                    className="password-toggle-icon "
                    onClick={() => setShowPassword2(!showPassword2)}
                  >
                    {showPassword2 ? (
                      <i className="fa fa-eye-slash" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-eye" aria-hidden="true"></i>
                    )}
                  </span>
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <div style={{ width: "140px", whiteSpace: "nowrap" }}>
                  <label style={{ fontSize: "15px", marginBottom: "1px" }}>R Password
                    <span className="text-danger"> * </span>{" "}
                  </label>
                </div>
                <div className="d-flex flex-column w-100">
                  <input
                    type="text"
                    placeholder="Enter your Terminal Confirm Password"
                    value={terminalpasswordreadonly}
                    onChange={(e) => setTerminalPasswordReadOnly(e.target.value)}
                    className="input rounded"
                    style={{ width: "100%", height: "35px", fontSize: "14px", }} />
                  {errors.terminalpasswordreadonly && (
                    <p
                      style={{ marginLeft: "5px", marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold">{errors.terminalpasswordreadonly}
                    </p>
                  )}
                </div>
              </div>

              <div className="box d-flex flex-row mb-2 gap-3">
                <div style={{ width: "140px", whiteSpace: "nowrap" }}>
                  <label style={{ fontSize: "15px", marginBottom: "1px", }}>E Date
                    <span className="text-danger"> * </span>{" "}</label>
                </div>
                <div className="d-flex flex-column" style={{ width: "100%" }}>
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={entrydates}
                    value={entrydates}
                    className="input rounded datepicker"
                    placeholderText="Select Entrydate"
                    style={{ width: "100%", height: "40px", fontSize: "12px" }}

                    onChange={(entrydates) => setEntrydates(entrydates)}
                  />
                         {errors.terminalpasswordreadonly && (
                    <p
                      style={{ marginLeft: "5px", marginBottom: "0px", fontSize: "12px" }}
                      className="errorMsg text-danger text-bold">{errors.terminalpasswordreadonly}
                    </p>
                  )}
                {errors.entrydates && (
                  <p
                    style={{ marginBottom: "3px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.entrydates}
                  </p>
                )}
                </div>
                
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "16px", marginBottom: "1px", width: "130px", whiteSpace: "nowrap" }}>V S</label>
                <div style={{ width: "100%" }}>
                  <Select
                    value={{ label: tpcName, value: tpc }}
                    onChange={(tpc) => {
                      setTpc(tpc.value);
                      setTpcName(tpc.label);
                    }}
                    options={tpclist}
                    placeholder="search T pc..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                <label style={{ fontSize: "15px", marginBottom: "1px", width: "130px" }}>T S</label>
                <div style={{ width: "100%" }}>
                  <Select
                    value={{ label: tsourceName, value: tsource }}
                    onChange={(tsource) => {
                      setTsource(tsource.value);
                      setTsourceName(tsource.label);
                    }}
                    options={tsourcelist}
                    placeholder="search T source..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
                </div>
              </div>

              <div>
                <div className="box d-flex align-items-center flex-row mb-2 gap-3">
                  <label style={{ fontSize: "16px", width: "130px", whiteSpace: "nowrap" }}>T Status
                    {/* <span className="text-danger" style={{ margin: "4px" }}>*</span> */}
                  </label>

                  <select
                    className="input rounded"
                    value={tstatus}
                    onChange={(e) => setTStatus(e.target.value)}
                    style={{ width: "100%", height: "40px", fontSize: "16px", border: '2px solid #ab9e9e99' }}>
                    <option value="">Select Status</option>
                    <>
                      <option value="active">active</option>
                      <option value="stop">stop</option>
                      <option value="deleted">delete</option>
                      <option value="need to set bot">need to set bot</option>
                    </>
                  </select>
                  {/* {errors.tstatus && (
                    <p
                      style={{ marginBottom: "3px" }}
                      className="errorMsg text-danger text-bold"
                    >
                      {errors.tstatus}
                    </p>
                  )} */}
                </div>
              </div>

              <div className="box d-flex align-items-center flex-row gap-3">
                <label style={{ fontSize: "15px", width: "130px", whiteSpace: "nowrap" }}>IB ID</label>
                <div style={{ width: "100%" }}>
                  <Select
                    value={{ label: IbName, value: iblist }}
                    onChange={(ib) => {
                      setIbList(ib.value);
                      setIbName(ib.label);
                    }}
                    options={dropdown1}
                    placeholder="search IB Id..."
                    styles={{
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        fontWeight: 400,
                        fontSize: '16px'
                      }),
                      menu: (provided, state) => ({
                        ...provided,
                        fontSize: '15px',
                        padding: '4px',
                      }),
                      control: (provided, state) => ({
                        ...provided,
                        border: '2px solid #ab9e9e99',
                        borderRadius: '7px',
                        width: '100%'
                      }),
                      singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      }),
                      valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        fontSize: '16px',
                        fontWeight: '400'
                      })
                    }}
                  />
                </div>
              </div>

              <div className="modal-footer d-flex " style={{ justifyContent: 'center' }}>
                <button
                  type="button"
                  className="btn btn-secondary me-5 "
                  data-bs-dismiss="modal"
                  onClick={() => setModalEdit(!modalEdit)}>
                  Close
                </button>
                <button type="submit" className="btn btn-primary ms-5">
                  Submit
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null}

      {/* user edit modal */}

      <Modal
        size="ig"
        isOpen={usermodal}
        toggle={() => setUsermodal(!usermodal)}>
        <ModalHeader toggle={() => setUsermodal(!usermodal)}>
          <form
            style={{ margin: "10px 70px" }}
            className="login"
            onSubmit={(event) => handleUserEditSubmit(event)}
          >
            <div className="box" style={{}}>
              <label style={{ fontSize: "16px", display: "block" }}>
                User Name  <span className="text-danger" style={{ margin: "4px" }}>*</span>
              </label>
              <input
                type="text"
                className="input rounded"
                value={name}
                placeholder="Enter your username"
                onChange={(e) => setName(e.target.value)}
                style={{ width: "100%", height: "40px", fontSize: "16px" }} />

              {errors.name && (
                <p
                  style={{ marginBottom: "3px", fontSize: "14px" }}
                  className="errorMsg text-danger text-bold"
                >
                  {errors.name}
                </p>
              )}
            </div>
            <div style={{}}>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Email  <span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  className="input rounded"
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />
                {errors.email && (
                  <p
                    style={{ marginBottom: "3px", fontSize: "14px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.email}
                  </p>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>Password  <span className="text-danger" style={{ margin: "4px" }}>*</span></label>
              <div className="position-relative">
                <input
                  type={!showPassword ? "text" : "password"}
                  className="input rounded"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                    paddingRight: "40px",
                  }}
                />
                {errors.password && (
                  <p className="errorMsg text-danger text-bold " style={{ marginBottom: "3px", fontSize: "14px" }}>
                    {errors.password}
                  </p>
                )}
                <span
                  className="password-toggle-icon"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  )}
                </span>
              </div>

            </div>
            <div style={{ marginBottom: "6px" }}>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Note</label>
                <input
                  type="text"
                  placeholder="Enter your note"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="input rounded"
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />
              </div>
            </div>
            <div>
              <div className="box  d-flex flex-column">
                <label style={{ fontSize: "16px" }}>Entry Date  <span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  selected={entrydate}
                  value={entrydate}
                  className="input rounded w-100 py-1"
                  onChange={(entrydate) => setEntrydate(entrydate)}
                  placeholderText="Select Date"
                />

                {errors.entrydate && (
                  <p
                    style={{ marginBottom: "3px", fontSize: "14px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.entrydate}
                  </p>
                )}
              </div>
            </div>


            <div style={{ marginBottom: "6px" }}>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Bank Email</label>
                <input
                  type="text"
                  placeholder="Enter your bank email"
                  value={bankemail}
                  onChange={(e) => setBankEmail(e.target.value)}
                  className="input rounded"
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />
              </div>
            </div>
            <div style={{ marginBottom: "6px" }}>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Bank Password</label>
                <input
                  type="text"
                  placeholder="Enter your bank Password"
                  value={bankpassword}
                  onChange={(e) => setBankPassword(e.target.value)}
                  className="input rounded"
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />
              </div>
            </div>

            <div style={{ marginBottom: "6px" }}>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Contact Number</label>
                <input
                  type="text"
                  placeholder="Enter your contact number"
                  value={contactnumber}
                  onChange={(e) => setContactNumber(e.target.value)}
                  className="input rounded"
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />
              </div>
            </div>

            <div>
              <div className="box">
                <label style={{ fontSize: "16px" }}>Role <span className="text-danger" style={{ margin: "4px" }}>*</span> </label>

                <select
                  className="input rounded"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                >
                  <option value="">Select Role</option>
                  {userEmail1 === "superadmin@gmail.com" ? (
                    <>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                      <option value="ib">IB</option>
                    </>
                  ) : (
                    <></>
                  )}
                  {userEmail === "Admin" && (
                    <>
                      <option value="user">User</option>
                      <option value="IB">IB</option>
                    </>
                  )}
                </select>
                {errors.role && (
                  <p
                    style={{ marginBottom: "3px", fontSize: "14px" }}
                    className="errorMsg text-danger text-bold"
                  >
                    {errors.role}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-secondary "
                data-bs-dismiss="modal"
                onClick={() => closeModelReset()}>
                Close
              </button>
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </ModalHeader>
      </Modal>

      {/* add currency popup */}

      {addmainusers.length > 0 && modalAddCurrency ? (
        <Modal
          size="ig"
          // style={{ width: "600px" }}
          isOpen={modalAddCurrency}
          toggle={() => setModaAddCurrency(!modalAddCurrency)}>
          <ModalHeader toggle={() => setModaAddCurrency(!modalAddCurrency)}>
            <form
              style={{ margin: "10px 10px" }}
              className="login"
              onSubmit={(event) => addCurrencySubmit(event)}>
              <h6>ADD CURRENCY COUNT</h6>
              <table className="table table-striped table-bordered table-responsive ">
                <thead>
                  <tr>
                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                      User ID
                    </th>
                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                      Terminal iD
                    </th>
                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                      Currency Type
                    </th>
                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                      count
                    </th>
                    {/* <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                      Action
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {currencyType?.map((currency, i) => (
                    <tr key={i}>
                      <td>
                        <h6 className="font-weight-bold mb-0 ms-3">{uidcurrency}</h6>
                      </td>
                      <td>
                        <h6 className=" font-weight-bold mb-0 ms-3">{tidcurrency}</h6>
                      </td>
                      <td>
                        <h6 className="font-weight-bold mb-0 ms-4">{currency.name}</h6>
                      </td>
                      <td>
                        <h6 className=" font-weight-bold mb-0 ms-1">
                          <input
                            type="text"
                            style={{ width: "50px" }}
                            placeholder=""
                            value={countValues[i]}
                            onChange={(e) => updateCountValue(i, e.target.value)}
                            className="input rounded"
                          >
                          </input>
                        </h6>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </table>

              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setModaAddCurrency(!modalAddCurrency)}>Close
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}>
                  {isLoading ? (<i className="fa fa-spinner fa-spin"></i>) : ("Save")}
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null}

      {/* file upload popup */}

      {addmainusers.length > 0 && modalUpload ? (
        <Modal
          size="xl"
          style={{ width: "600px" }}
          isOpen={modalUpload}
          toggle={() => setModaUpload(!modalUpload)}>
          <ModalHeader toggle={() => setModaUpload(!modalUpload)}>
            <form
              style={{ margin: "10px 87px" }}
              className="login"
              encType="multipart/form-data"
              onSubmit={(event) => uploadFile(event)}>
              <h6>ADD FILE</h6>
              <div class="mb-3">
                <input type="file" name="file"
                  style={{ border: "1px solid grey", borderRadius: "5px", fontSize: '16px', width: "387px" }}
                  onChange={(e) => handleFiles(e)}
                />
              </div>

              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setModaUpload(!modalUpload)}>Close
                </button>

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}>
                  {isLoading ? (<i className="fa fa-spinner fa-spin"></i>) : ("UPLOAD")}
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null}

      {/* file download popup */}

      {addmainusers.length > 0 && modalDownload ? (
        <Modal
          size="xl"
          style={{ width: "1000px" }}
          isOpen={modalDownload}
          toggle={() => setModaUpload(!modalDownload)}>
          <ModalHeader toggle={() => setModalDownload(!modalDownload)}>
            <h6>DOWNLOAD YOUR DOCUMENT</h6>
            <table className="table table-striped table-bordered " style={{ width: "100px" }}>
              <thead>
                <tr>
                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                    Terminal ID
                  </th>
                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                    File Name
                  </th>
                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {uploadedFiles?.map((files, i) => (
                  <tr key={i}>
                    <td>
                      <h6 className="font-weight-bold mb-0 ms-3">{tidcurrency}</h6>
                    </td>
                    <td>
                      <h6 className="font-weight-bold mb-0 ms-1" style={{ textTransform: "none" }}>
                        {routePath === 'https://api.fxgava.com/api/stock' ? 'https://api.fxgava.com' : 'http://192.168.1.19:33333'}{files.upload_file}</h6>
                    </td>
                    <td>
                      <span
                        title="Delete"
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handledeleteFile(files.id)}>
                        <i
                          className="fa fa-trash fa-lg mt-2 ms-1"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </td>

                  </tr>
                ))}

              </tbody>
            </table>
            <div className="modal-footer d-flex justify-content-center">
              <button
                type="button"
                className="btn btn-secondary "
                data-bs-dismiss="modal"
                onClick={() => setModalDownload(!modalDownload)}>Close
              </button>

              {/* <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={isLoading}>
                  {isLoading ? (<i className="fa fa-spinner fa-spin"></i>) : ("Save")}
                </button> */}
            </div>
          </ModalHeader>
        </Modal>
      ) : null}
    </>
  )
}
