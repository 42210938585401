import Swal from 'sweetalert2';
import 'animate.css';

export const Showalert=(message, type)=>{
    const Toast = Swal.mixin({
        toast: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown ' 
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        },
      })
    Toast.fire({
        icon: type, // success | error | warning | info
        title: message
    });
  };

export const Showdilog=(title,text)=>{
      return Swal.fire({
        title: title,
        // text: text,
      }).then((result) => {
        return false;
      })
};

export const showAlert = (message, type) => {
  const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
  });
  Toast.fire({
      icon: type, // success | error | warning | info
      title: message
  });
};

export const showConfirmationDialog  = (title, description, type) => {
  return Swal.fire({
      title: title,
      text: description,
      icon: type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
  }).then((result) => {
      if (result.isConfirmed) {
          return true;
      }
      return false;
  });
};

    