import React, { useEffect } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { Cookies  } from 'react-cookie';

function AccessControl() {

  const cookies = new Cookies();
  const ifUser = cookies.get("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!ifUser) {
      localStorage.clear();
      window.location.reload();
    }
  }, [ifUser, navigate]);

  return ifUser ? <Outlet/> : <Navigate to="/login" replace/>
}  

  export default AccessControl;