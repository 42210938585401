import './App.css';
import { useEffect, useState } from 'react';

import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from './components/Dashboard';
// import '../public/assets/css/material-dashboard.css'
import '../src/assets/css/material-dashboard.css'

import UsedetailsTable from './components/UsedetailsTable';
import Login from './components/Login';
import TerminalAccount from './components/TerminalAccount';
import Wallet from './components/Wallet';
import History from './components/History';
import AccessControl from './components/AccessControl';
import UserAmount from './components/UserAmount';
import Balance from './components/Balance';
import Changepassword from './components/Changepassword';
import Activepostions from './components/Activepositions';
import Demo from './components/Demo';
import Cycle from './components/Cycle';
import PendingAccount from './components/PendingAccount';
import T_Source from './components/T_Source';
import T_Pc from './components/T_Pc';
import Currency from './components/Currency';
import ChangeSuperPassword from './components/ChangeSuperPassword';
import { Cookies  } from 'react-cookie';
import IbUser from './components/IbUser';

function App() {
  const cookies = new Cookies();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [user, setUser] = useState();
  useEffect(()=>{
    const token =  cookies.get("token");
    setUser(token);
  },[user]);

  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
    {
      user ? (
        isSidebarOpen ? (
        <button className="btn btn-info position-fixed ms-2" style={{marginTop:"22px",marginBottom:"8px", zIndex:'99999999999',padding:'3px 8px'}} 
        onClick={toggleSidebar}><i className="fa fa-solid fa-bars " style={{color: '#ffffff'}}  ></i></button>
        ) : (
          <button className="btn btn-info position-fixed" style={{marginTop:"30px",zIndex:'99999999999',padding:'3px 8px'}} 
          onClick={toggleSidebar}><i className="fa fa-solid fa-bars " style={{color: '#ffffff'}}  ></i></button>
        )
        ) : (
        null
        )
    }

      <BrowserRouter>
        <Routes>
          <Route path='/login' element={<Login/>}></Route>
          <Route element={<AccessControl />}>
            <Route path='/' element={<Dashboard isSidebarOpen={isSidebarOpen}/>}/>
            <Route path="/userdetails/:type" element={<UsedetailsTable isSidebarOpen={isSidebarOpen}/>} />
            {/* <Route path="/userdetails/ib" element={<UsedetailsTable isSidebarOpen={isSidebarOpen}/>} />
            <Route path="/userdetails/bot" element={<UsedetailsTable isSidebarOpen={isSidebarOpen}/>} /> */}
            <Route path="/cycle" element={<Cycle isSidebarOpen={isSidebarOpen}/>} />
            <Route path="/tsource" element={<T_Source isSidebarOpen={isSidebarOpen}/>} />     
            <Route path="/tpc" element={<T_Pc isSidebarOpen={isSidebarOpen}/>} />       
            <Route path="/currency" element={<Currency isSidebarOpen={isSidebarOpen}/>} />       
            <Route path='/terminalaccounts' element={<TerminalAccount isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/transaction' element={<Wallet isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/history' element={<History isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/userlist' element={<UserAmount isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/balance' element={<Balance isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/changepassword' element={<Changepassword isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/changesuperpassword' element={<ChangeSuperPassword isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/activeposition/:terminal_id' element={<Activepostions isSidebarOpen={isSidebarOpen}/>}/>
            <Route path='/demo' element={<Demo/>}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
