import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { putMethod } from '../service/base_service';
import { Apiurl } from '../service/apiRoute';
import { showAlert } from '../service/alert_service';
import { useNavigate } from 'react-router-dom';

export default function ChangeSuperPassword(props) {

  const [newpassword, setNewpassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [confirmNewpassword, setConfirmNewpassword] = useState("");

  const navigate = new useNavigate()

  const handlechange = (item, e) => {

    if (item === "oldpassword") {
      setOldPassword(e.target.value);
    } else if (item === "newpassword") {
      setNewpassword(e.target.value);
    } else if (item === "confirmnewpassword") {
      setConfirmNewpassword(e.target.value);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      if (oldPassword === "") {
        showAlert("please input old password.", "error");
      } else if (newpassword == "") {
        showAlert("please input new password", "error");
      } else if (confirmNewpassword == "") {
        showAlert("please input comfirm password", "error");
      } else if (confirmNewpassword !== newpassword) {
        showAlert("comfirm password doesn't match with new password", "error");
      }
      else {
        const params = {
          old_password: oldPassword,
          new_password: newpassword
        };
        const response = await putMethod(Apiurl.change_password, params);
        if (response) {
          showAlert(response.data.message, 'success');
          setTimeout(() => {
            navigate("/");
          }, 1000)
        }
      }
    } catch (err) {
      showAlert(err.response.data.message, 'error');
    }
  }

  return (
    <>
      <Header />
      <Sidebar isSidebarOpen={props.isSidebarOpen} />
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                    <h6 className="text-white text-capitalize ps-3 mt-3">
                      CHANGE SUPER ADMIN PASSWORD
                    </h6>
                  </div>
                </div>
                <div className="container my-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-8 col-12 mx-auto">
                      <div className="card z-index-0 fadeIn3 fadeInBottom">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                        </div>
                        <div className="card-body">

                          <form role="form" className="text-start" onSubmit={handleSubmit}>
                            <label className="form-label mt-2 mb-0" style={{ marginTop: '10px' }}>Old Password</label>
                            <div className="input-group input-group-outline">
                              <input type="text" className="form-control" value={oldPassword} onChange={(event) => handlechange("oldpassword", event)} />
                            </div>
                            <label className="form-label mt-2 mb-0">New Password</label>
                            <div className="input-group input-group-outline">
                              <input type="text" className="form-control" value={newpassword} onChange={(event) => handlechange("newpassword", event)} />
                            </div>
                            <label className="form-label mt-2 mb-0">Confirm New Password</label>
                            <div className="input-group input-group-outline">
                              <input type="text" className="form-control" value={confirmNewpassword} onChange={(event) => handlechange("confirmnewpassword", event)} />
                            </div>
                            <div className="text-center">
                              <button type="submit" className="btn bg-info bg-gradient w-100 my-4 mb-2 text-white">Change</button>
                            </div>

                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}
