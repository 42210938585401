import React, { useEffect, useState } from 'react'
import { Modal, ModalHeader } from "reactstrap";
import Sidebar from './Sidebar'
import Header from './Header'
import { getMethod, postMethod } from '../service/base_service';
import { Apiurl } from '../service/apiRoute';
import { showAlert } from '../service/alert_service';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import moment from "moment";


function Cycle(props) {

    const [name, setName] = useState("");
    const [datefrom, setDateFrom] = useState("");
    const [dateto, setDateTo] = useState("");
    const [errors, setErrors] = useState({});
    const [modal, setModal] = useState(false);
    const [entrydates, setEntrydates] = useState();


    const loginUserDetail = localStorage?.getItem("user");
    const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
    const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

    const loginUserDetail1 = localStorage?.getItem("user");
    const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
    const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;


    const fetchDates = async () => {
        try {
            const response = await getMethod(Apiurl.cycle);
            if (response.data) {
                setEntrydates(response.data);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        fetchDates();
    }, []);

    const navigate = new useNavigate()

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!name) {
            errors.name = "Please Enter the name";
        }
        if (!dateto) {
            errors.dateto = "Please Enter the from date";
        }
        if (!datefrom) {
            errors.datefrom = "Please Enter the date to";
        }
        setErrors(errors);
        if (Object.keys(errors).length !== 0) {
            showAlert("Please enter proper data.", "error");
            return;
        }
        if (!errors.length > 0) {
            const dates = {
                name: name,
                date_from: datefrom.toISOString(),
                date_to: dateto.toISOString()
            };

            dates.date_from = new Date(new Date(dates.date_from).getTime() + 5.5 * 60 * 60 * 1000).toISOString();
            dates.date_to = new Date(new Date(dates.date_to).getTime() + 5.5 * 60 * 60 * 1000).toISOString();

            const response = await postMethod(Apiurl.cycle, dates);
            if (response.success) {
                showAlert(response.message, "success");

                setModal(false)
                fetchDates();
            }
            else {
                showAlert(response.message, "error");
            }
        } else {

        }
    }
    return (
        <>

            <Header />
            <Sidebar isSidebarOpen={props.isSidebarOpen} />
            <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>

                <Modal size="ig" isOpen={modal} toggle={() => setModal(!modal)}>
                    <ModalHeader toggle={() => setModal(!modal)}>
                        <form style={{ margin: "10px 70px" }} className="login" onSubmit={handleSubmit}>
                            <div className="box" style={{}}>
                                <label style={{ fontSize: "16px", display: "block" }}> Name
                                    <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded"
                                    value={name}
                                    placeholder="Enter your name"
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ width: "100%", height: "40px", fontSize: "16px" }} />
                                {errors.name && (
                                    <p
                                        style={{ marginBottom: "3px" }}
                                        className="errorMsg text-danger text-bold">
                                        {errors.name}
                                    </p>
                                )}
                            </div>

                            <div>
                                <div className="box  d-flex flex-column">
                                    <label style={{ fontSize: "16px" }}>Date From<span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={datefrom}
                                        className="input rounded w-100 py-1"
                                        onChange={(datefrom) => setDateFrom(datefrom)}
                                        placeholderText="Select date from" />
                                     {errors.datefrom && (
                                        <p
                                            style={{ marginBottom: "3px" }}
                                            className="errorMsg text-danger text-bold">
                                            {errors.datefrom}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div>
                                <div className="box  d-flex flex-column">
                                    <label style={{ fontSize: "16px" }}>Date To<span className="text-danger" style={{ margin: "4px" }}>*</span></label>
                                    <DatePicker
                                        dateFormat="dd/MM/yyyy"
                                        selected={dateto}
                                        className="input rounded w-100 py-1"
                                        onChange={(dateto) => setDateTo(dateto)}
                                        placeholderText="Select date to" />

                                    {errors.dateto && (
                                        <p
                                            style={{ marginBottom: "3px" }}
                                            className="errorMsg text-danger text-bold">
                                            {errors.dateto}
                                        </p>
                                    )}
                                </div>
                            </div>

                            <div className="modal-footer d-flex justify-content-between">
                                <button type="button" className="btn btn-secondary " data-bs-dismiss="modal"
                                    onClick={() => setModal(!modal)}>Close
                                </button>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </ModalHeader>
                </Modal>

                <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="card my-4">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>

                                        <h6 className="text-white text-capitalize ps-3 mt-3">
                                            Cycle Details
                                        </h6>

                                        {userEmail1 === "superadmin@gmail.com" ||
                                            userEmail === "Admin" ? (
                                            <li className="nav-item d-flex align-items-center">
                                                <button
                                                    onClick={() => setModal(true)}
                                                    className="btn text-white d-flex mt-3 me-3 border border-white">
                                                    <i className="fa fa-plus-circle fa-xl fa-lg mt-1 me-1" style={{ color: "white" }} aria-hidden="true"></i>Add Cycle
                                                </button>
                                            </li>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="card-body px-0 pb-2">
                                    <div className="table-responsive p-0">
                                        <table
                                            className="table align-items-center mb-0"
                                            style={{ width: "100%" }}>
                                            <thead >
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">Name</th>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">Date From</th>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">Date To</th>
                                                </tr>
                                            </thead>
                                            <tbody className="table align-items-center">
                                                {entrydates &&
                                                    entrydates.map((data) => (
                                                        <tr key={data.id}>
                                                            <td> <h6 className=" font-weight-bold mb-0 ms-2">{data.name}</h6></td>
                                                            <td> <h6 className=" font-weight-bold mb-0 ms-2">
                                                            {data.date_from.substring(8, 10)}/{data.date_from.substring(5, 7)}/{data.date_from.substring(0, 4)}
                                                            </h6></td>
                                                            <td> <h6 className=" font-weight-bold mb-0 ms-2">
                                                            {data.date_to.substring(8, 10)}/{data.date_from.substring(5, 7)}/{data.date_from.substring(0, 4)}
                                                            </h6></td>        
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container-fluid py-4">
                    <div className="row">
                        <div className="col-12">
                            <div className="card my-4">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                                        <h6 className="text-white text-capitalize ps-3 mt-3">
                                            Cycle Details
                                        </h6>
                                        {userEmail1 === "superadmin@gmail.com" ||
                                            userEmail === "Admin" ? (
                                            <li className="nav-item d-flex align-items-center">
                                                <button
                                                    onClick={() => setModal(true)}
                                                    className="btn d-flex mt-3 me-3">
                                                    <i className="fa fa-plus-circle fa-xl fa-lg mt-1 me-1" style={{ color: "white" }} aria-hidden="true"></i>     
                                                </button>
                                            </li>
                                        ) : (
                                            <></>
                                        )}

                                    </div>
                                </div>
                                <div className="card-body px-0 pb-2">
                                    <div className="table-responsive p-0">
                                                                             

                                                <table>
                                                    <thead>
                                                        <tr>
                                                        <th>Name</th>
                                                        <th>Date From</th>
                                                        <th>Date To</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </main>
        </>
    )
}

export default Cycle