import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { getMethod, postMethod } from '../service/base_service';
import { Apiurl } from '../service/apiRoute';
import { Showdilog, showAlert } from '../service/alert_service';
import { Navigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function UserAmount(props) {

  const [userwallet, setUserWallet] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [modalamount, setAddamount] = useState(false);
  const [withdrAmount, setWithdrawAmount] = useState("");
  const [withdrUsdRate, setWithdrawAmountUsdRate] = useState("");
  const [type, setType] = useState("");
  const [errors, setErrors] = useState({});


  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;





  useEffect(() => {
    // Fetch user data from API and update the state
    fetchuserammount();
  }, []);




  const fetchuserammount = async () => {
    try {
      // Make an API request to fetch user data
      const response = await getMethod(Apiurl.userlistwallet);
      if (response) {
        setUserWallet(response.data);
        setIsLoading(false);

      }
    } catch (error) {
      setIsLoading(false);

    }
  };







  const AddmodalwithdrawalAmount = (userId) => {
    setAddamount(true);
    setUserId(userId);
  };

  const AddwithdrawalAmountubmit = async (event) => {
    event.preventDefault();

    if (type === "INR" && (typeof withdrUsdRate === "undefined" || withdrUsdRate === "")) {
      showAlert("Please enter the USD to INR exchange rate.", "error");
      return;
    }
    const res = {
      userId,
      amount: parseFloat(withdrAmount)/parseInt(withdrUsdRate) || parseFloat(withdrAmount),
      currencyType: type,
      per_doller_amount: parseInt(withdrUsdRate) || 0
    };

    const response = await postMethod(Apiurl.withdrawProfitAmount, res);
    if (response.success) {
      Showdilog(response.message, "success");
      fetchuserammount();

      resetForm();
    } else {
      Showdilog(response.message, "failed");
      resetForm();
    }
    setAddamount(false);
  };

  const resetForm = () => {
    setWithdrawAmount('');
    setUserId('');
    setWithdrawAmountUsdRate('');

  }

  return (
    <>

      <Header/>
      <Sidebar isSidebarOpen={props.isSidebarOpen}/>
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
        <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{height:"55px"}}>
                    <h6 className="text-white text-capitalize ps-3 mt-3">
                      USER AMOUNT DETAIL
                    </h6>
                  </div>
                </div>
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    {


                      userEmail1 === "superadmin@gmail.com" || userEmail === "Admin" ? (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                User Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Gross Amount
                              </th>

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Withdrawal
                              </th>

                            </tr>
                          </thead>
                          <tbody className="table align-items-center">

                            {
                              isLoading ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    <span className="loader"></span>
                                  </td>
                                </tr>  // replace this with your custom loader component
                              ) : (
                                userwallet.map((amount, index) => (



                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {amount?.user_name}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {amount?.total_amount}
                                        </h6>
                                      </td>

                                      <td className="ms-2">
                                        <button
                                          className="btn btn-outline-dark d-flex  mb-0 me-3 p-1 ms-2"
                                          style={{
                                            color: "black",
                                            border: "1px solid green",
                                          }}
                                          target="_blank"
                                          onClick={() => AddmodalwithdrawalAmount(amount?.id)}
                                          href="https://www.creative-tim.com/builder?ref=navbar-material-dashboard"
                                        >
                                          <i
                                            className="fa fa-inr fa-lg mt-1 me-2"
                                            style={{ color: "black" }}
                                            aria-hidden="true"
                                          ></i>
                                          Withdrawal{" "}
                                        </button>
                                      </td>

                                    </tr>
                                  </React.Fragment>
                                ))
                              )}
                          </tbody>
                        </table>
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                User Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Gross Amount
                              </th>

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Withdrawal
                              </th>

                            </tr>
                          </thead>
                          <tbody className="table align-items-center">

                            {
                              isLoading ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    <span className="loader"></span>
                                  </td>
                                </tr>  // replace this with your custom loader component
                              ) : (
                                userwallet.map((amount, index) => (



                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {amount?.user_name}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {amount?.total_amount}
                                        </h6>
                                      </td>

                                      <td className="ms-2">
                                        <button
                                          className="btn btn-outline-dark d-flex  mb-0 me-3 p-1 ms-2"
                                          style={{
                                            color: "black",
                                            border: "1px solid green",
                                          }}
                                          target="_blank"
                                          onClick={() => AddmodalwithdrawalAmount(amount?.id)}
                                          href="https://www.creative-tim.com/builder?ref=navbar-material-dashboard"
                                        >
                                          <i
                                            className="fa fa-inr fa-lg mt-1 me-2"
                                            style={{ color: "black" }}
                                            aria-hidden="true"
                                          ></i>
                                          Withdrawal{" "}
                                        </button>
                                      </td>

                                    </tr>
                                  </React.Fragment>
                                ))
                              )}
                          </tbody>
                        </table>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>



      {userwallet?.length > 0 && modalamount ? (
        <Modal
          size="ig"
          isOpen={modalamount}
          toggle={() => setAddamount(!modalamount)}
        >
          <ModalHeader toggle={() => setAddamount(!modalamount)}>
            <form
              style={{ margin: "10px 70px" }}
              className="login"
              onSubmit={(event) => AddwithdrawalAmountubmit(event)}
            >
              <div className="box">
                <label style={{ fontSize: "16px", display: "block" }}>
                  Withdraw Amount <span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>
                <div className="box">
                  <select
                    className="input rounded"
                    value={type}
                    required
                    onChange={(event) => setType(event.target.value)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  >
                    <option value="">Select Curruncy Type</option>

                    <option value="USD">USD</option>
                    <option value="INR">INR</option>
                  </select>
                </div>
                <input
                  type="text"
                  className="input rounded"
                  value={withdrAmount}
                  required
                  placeholder="Enter your Amount"
                  onChange={(event) => setWithdrawAmount(event.target.value)}
                  style={{ width: "100%", height: "40px", fontSize: "16px", marginTop: "5px" }}
                />
                {
                  type == "INR" && (
                    <input
                      type="text"
                      className="input rounded"
                      value={withdrUsdRate}
                      required
                      placeholder="Indian Rupees Per Doller"
                      onChange={(event) => setWithdrawAmountUsdRate(event.target.value)}
                      style={{ width: "100%", height: "40px", fontSize: "16px", marginTop: "5px" }}
                    />
                  )
                }

              </div>

              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setAddamount(!modalamount)}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null}


    </>
  )
}
