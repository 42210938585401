import React, { useEffect, useState } from "react";
import Header from "./Header";

import Sidebar from "./Sidebar";
import { getMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { useParams } from "react-router";
import { type } from "@testing-library/user-event/dist/type";
import { Backdrop } from "@material-ui/core";

export default function Activepostions(props) {
  const [activeuser, setActiveuser] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;


  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1
    ? JSON.parse(loginUserDetail1)
    : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;

  useEffect(() => {
    fetchactiveposition();
  }, []);

  const fetchactiveposition = async () => {
    try {
      const terminal_id = params.terminal_id;
      const response = await getMethod(Apiurl.activeuser + "/" + terminal_id);
      if (response) {
        setActiveuser(response.data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  function formatTime(timestamp) {
    const date = new Date(timestamp);

    const formatDate = `${date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formatTime = `${hours % 12 === 0 ? 12 : hours % 12}:${minutes
      .toString()
      .padStart(2, "0")}${ampm}`;

    return { date: formatDate, time: formatTime };
  }
  return (
    <>
      <Sidebar isSidebarOpen={props.isSidebarOpen} />
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
        <Header></Header>
        <div className="container-fluid py-4">
          <div className="container-fluid py-4">
            <div className="row">
              <div className="col-12">
                <div className="card my-4">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="bg-info bg-gradient shadow-primary border-radius-lg pt-4 pb-3 d-flex justify-content-between">
                      <h6 className="text-white text-capitalize ps-3">
                        POSITIONS DETAILS
                      </h6>
                      <li className="nav-item d-flex align-items-center">
                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                          <div className="input-group input-group-outline">
                            <label
                              className="form-label"
                              style={{ color: "white" }}
                            ></label>
                          </div>
                        </div>
                      </li>
                    </div>
                  </div>
                  <div className="card-body px-0 pb-2">
                    <div className="table-responsive p-0">
                      {userEmail1 === "superadmin@gmail.com" ||
                        userEmail === "Admin" ? (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal_Id
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Ticket
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Symbol
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Volume
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Price_open
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Price_current
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Tp
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Profit
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Time
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Time_update
                              </th>



                            </tr>
                          </thead>

                          <tbody className="table align-items-center">
                            {isLoading ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span className="loader"></span>
                                </td>
                              </tr>
                            ) : activeuser.length > 0 ? (
                              activeuser?.map((activepositions, index) => (

                                <React.Fragment key={index}>
                                  {
                                    activepositions?.type === 1 ? (
                                      <tr style={{ background: "#f8baba" }}>
                                        <td className="black-color">
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>{activepositions?.terminal_id}</h6>
                                        </td>
                                        <td className="black-color">
                                          <h6 className="mb-0 ms-2" style={{ color: "black" }}>{activepositions?.ticket}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "blue" }}>{activepositions?.symbol}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>{activepositions?.volume}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>{activepositions?.price_open}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>{activepositions?.price_current}</h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "purple" }}>{activepositions?.tp}</h6>
                                        </td>
                                        <td>
                                          {activepositions?.profit < 0 ? (
                                            <h6 className="font-weight-bold mb-0 ms-2" style={{ color: "red" }}>{activepositions?.profit}</h6>
                                          ) : (
                                            <h6 className="font-weight-bold mb-0 ms-2" style={{ color: "green" }}>{activepositions?.profit}</h6>
                                          )}
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">{formatTime(activepositions?.time).date}<br />
                                            {formatTime(activepositions?.time).time}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">{formatTime(activepositions?.time_update).date}<br />
                                            {formatTime(activepositions?.time_update).time}
                                          </h6>
                                        </td>
                                      </tr>
                                    ) : (
                                      <tr style={{ background: "lightgreen" }}>
                                        <td className="black-color">
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.terminal_id}
                                          </h6>
                                        </td>
                                        <td className="black-color">
                                          <h6
                                            className="mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.ticket}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "blue" }}
                                          >
                                            {activepositions?.symbol}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.volume}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.price_open}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>
                                            {activepositions?.price_current}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "purple" }}
                                          >
                                            {activepositions?.tp}
                                          </h6>
                                        </td>
                                        <td>
                                          {activepositions?.profit < 0 ? (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "red" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          ) : (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "green" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          )}
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time).date}
                                            <br />
                                            {formatTime(activepositions?.time).time}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time_update).date}
                                            <br />
                                            {formatTime(activepositions?.time_update).time}

                                          </h6>
                                        </td>
                                      </tr>
                                    )
                                  }
                                </React.Fragment>
                              ))
                            ) : (
                              <div>
                                <h4>Records Not Found</h4>
                              </div>
                            )}
                          </tbody>
                        </table>
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal_Id
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Ticket
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Symbol
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Volume
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Price_open
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Price_current
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Tp
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Profit
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Time
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Time_update
                              </th>



                            </tr>
                          </thead>

                          <tbody className="table align-items-center">
                            {isLoading ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span className="loader"></span>
                                </td>
                              </tr>
                            ) : activeuser.length > 0 ? (
                              activeuser?.map((activepositions, index) => (

                                <React.Fragment key={index}>
                                  {
                                    activepositions?.type === 0 ? (

                                      <tr style={{ background: "#f8baba", height: "5px" }}>
                                        <td className="black-color">
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black", padding: '0' }}

                                          >
                                            {activepositions?.terminal_id}
                                          </h6>
                                        </td>
                                        <td className="black-color">
                                          <h6
                                            className="mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.ticket}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "blue" }}
                                          >
                                            {activepositions?.symbol}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.volume}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.price_open}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>
                                            {activepositions?.price_current}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "purple" }}
                                          >
                                            {activepositions?.tp}
                                          </h6>
                                        </td>
                                        <td>
                                          {activepositions?.profit < 0 ? (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "red" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          ) : (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "green" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          )}
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time).date}
                                            <br />
                                            {formatTime(activepositions?.time).time}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time_update).date}
                                            <br />
                                            {formatTime(activepositions?.time_update).time}

                                          </h6>
                                        </td>

                                      </tr>

                                    ) : (

                                      <tr style={{ background: "lightgreen" }}>
                                        <td className="black-color">
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.terminal_id}
                                          </h6>
                                        </td>
                                        <td className="black-color">
                                          <h6
                                            className="mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.ticket}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "blue" }}
                                          >
                                            {activepositions?.symbol}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.volume}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "black" }}
                                          >
                                            {activepositions?.price_open}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2" style={{ color: "black" }}>
                                            {activepositions?.price_current}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6
                                            className=" mb-0 ms-2"
                                            style={{ color: "purple" }}
                                          >
                                            {activepositions?.tp}
                                          </h6>
                                        </td>
                                        <td>
                                          {activepositions?.profit < 0 ? (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "red" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          ) : (
                                            <h6
                                              className="font-weight-bold mb-0 ms-2"
                                              style={{ color: "green" }}
                                            >
                                              {activepositions?.profit}
                                            </h6>
                                          )}
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time).date}
                                            <br />
                                            {formatTime(activepositions?.time).time}
                                          </h6>
                                        </td>
                                        <td>
                                          <h6 className=" mb-0 ms-2">
                                            {formatTime(activepositions?.time_update).date}
                                            <br />
                                            {formatTime(activepositions?.time_update).time}

                                          </h6>
                                        </td>
                                      </tr>
                                    )
                                  }
                                </React.Fragment>
                              ))
                            ) : (
                              <div>
                                <h4>Records Not Found</h4>
                              </div>
                            )}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
