
export const Apiurl = {
  // routePath: "http://192.168.28.192:33333/api/stock",
  routePath: 'https://api.fxgava.com/api/stock',

  register: "/user/register",
  mainuser: "/usermain/user-main",
  getTemrminals: "/usermain/getTerminials",

  dashboard_data: "/usermain/dashboard-data",

  login: "/admin/login",
  admin: "/admin/admin",

  user: "/user/user",
  users: "/user",
  terminal_delete: "/usermain",
  changepassword: "/user/changepassword",
  terminalpassword: "/usermain",

  wallet: "/wallet/wallet",
  userlistwallet: "/wallet/user-list",
  withdrawProfitAmount: "/wallet/withdraw-amount",

  terminalamount: "/terminalamount/add-terminalamount",
  terminalamounts: "/terminalamount/terminalammount",

  terminalwallet: "/terminalamount/terminalwalleteamount",

  accountcommistion: "/commision/account_commistion",
  accountcommistions: "/commision/account_commistions",

  history: "/history",

  userlogin: "/login/user-login",
  userbalance: "/balance/add-balanceamount",
  userbalancewithdraw: "/balance/withdraw-balanceamount",
  usergetbalance: "/balance/balanceamount",

  userupdate: "/user",
  activeuser: "/positions/active_position",
  commision: '/commision',
  cycle:'/cycle',
  dropdown_data:'/usermain/dropdown-data',
  t_Pc:'/t-pc',
  t_source:'/t-source',
  set_bot:'/usermain/needtosetbot',
  currency:'/currency',
  user_currency:'/user_currency',
  change_password:'/change/password',
  docs_upload_download:'/usermain/docs',
  IbUsers:"/usermain/ib/list"
};
