import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Modal, ModalHeader } from "reactstrap";
import { deleteMethod, getMethod, postMethod, putMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Showdilog, showAlert, showConfirmationDialog } from "../service/alert_service";
import axios from "axios";
import "./Loader.css";
import { NavLink } from "react-router-dom";
import './Userdetail.css';

export default function Currency(props) {

    const loginUserDetail = localStorage?.getItem("user");
    const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
    const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

    const loginUserDetail1 = localStorage?.getItem("user");
    const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
    const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;

    const [isLoading, setIsLoading] = useState(true);

    const [modal, setModal] = useState(false);
    const [id, setId] = useState();
    const [currencyName, setCurrencyName] = useState("");

    const [currencyType, setCurrecyType] = useState();
    const [selectedTpc, setSelectedTpc] = useState();


    const [errors, setErrors] = useState({});

    useEffect(() => {
        fetchCurrency();
    }, []);

    const fetchCurrency = async () => {
        try {
            const response = await getMethod(`${Apiurl.currency}/get`);
            if (response) {
                setIsLoading(false)
            }
            setCurrecyType(response.data);
        } catch (error) {
        }
    };

    const setModalOpen = (()=>{
        setModal(true);
        setCurrencyName(null)
        setErrors('')
    })

    // const handleSearch = (e) => {
    //     const input = e.target.value.toLowerCase();
    //     setSearchInput(input);
    // };


    const handlechange = (item, e) => {
        const stateSetters = {
            currencyName: setCurrencyName
        };
        const setter = stateSetters[item];
        if (setter) {
            setter(e.target.value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!currencyName) {
            errors.currencyName = "Please Enter currency name";
        }

        setErrors(errors);
        if (Object.keys(errors).length !== 0) {
            showAlert("Please enter proper data.", "error");
            return;
        }
        const data = {
            name: currencyName
        };
        try {
            setIsLoading(true);
            setTimeout(async () => {
                const response = await postMethod(`${Apiurl.currency}/add`, data);
                if (response.success) {
                    Showdilog("Add currency type successful");
                    setModal(!modal)
                    fetchCurrency()
                } else {
                    Showdilog(response.message, "Add currency type failed");
                }
            }, 1000);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handledelete = async (id) => {
        const confirmation = await showConfirmationDialog("Are you sure you want to delete ?","","delete");
        if (confirmation) {
            const response = await deleteMethod(`${Apiurl.currency}/delete/${id}`);
            if (response.success) {
                Showdilog(response.message,"success");
                fetchCurrency();
            } else {
                Showdilog(response.message, "error");
            }
        }
    };
  return (
    <>
    <Header />
    <Sidebar isSidebarOpen={props.isSidebarOpen} />
    <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
    
    <Modal size="ig" isOpen={modal} toggle={() => setModal(!modal)}>
            <ModalHeader toggle={() => setModal(!modal)}>
                <form style={{ margin: "10px 70px" }} className="login" onSubmit={handleSubmit}>
                    <div className="box" style={{}}>
                        <label style={{ fontSize: "16px", display: "block" }}>
                            Currency type <span className="text-danger" style={{ margin: "4px" }}>*</span>
                        </label>

                        <input
                            type="text"
                            className="input rounded"
                            value={currencyName}
                            placeholder="Enter currency name"
                            onChange={(event) => handlechange("currencyName", event)}
                            style={{ width: "100%", height: "40px", fontSize: "16px" }} />
                        {errors.currencyName && (
                            <p style={{ marginBottom: "3px" }} className="errorMsg text-danger text-bold">{errors.currencyName}</p>
                        )}
                    </div>

                    <div className="modal-footer d-flex justify-content-between">
                        <button
                            type="button"
                            className="btn btn-secondary "
                            data-bs-dismiss="modal"
                            onClick={() => setModal(!modal)}>Close
                            </button>
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={isLoading}>
                            {isLoading ? (<span className="loader" style={{ zIndex: "10000" }}></span>) : ("Submit")}
                        </button>
                    </div>
                </form>
            </ModalHeader>
    </Modal>
        <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
            <div className="row">
                <div className="col-12">
                    <div className="card my-4">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                            <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                                <h6 className="text-white text-capitalize ps-3">
                                    {/* <li className="nav-item d-flex align-items-center">
                                        <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                            <div className="input-group input-group-outline mt-2">
                                                <input
                                                    type="text"
                                                    className="form-control text-white"
                                                    placeholder="Search here...."
                                                    value={searchInput}
                                                    onChange={handleSearch}
                                                />
                                            </div>
                                        </div>
                                    </li> */}
                                </h6>

                                {userEmail1 === "superadmin@gmail.com" ||
                                    userEmail === "Admin" ? (
                                    <li className="nav-item d-flex align-items-center">
                                        <button
                                            onClick={() => setModalOpen()}
                                            className="btn btn-outline-dark d-flex mt-3 me-3"
                                            style={{ color: "white", border: "1px solid white" }}>
                                            <i className="fa fa-sharp fa-regular fa-coins mt-1 me-1" style={{ color: "white" }} aria-hidden="true"></i>
                                            Add 
                                        </button>
                                    </li>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="card-body px-0 pb-2">
                            <div className="table-responsive p-0">
                                {
                                    userEmail1 === `superadmin@gmail.com` ||
                                        userEmail === "Admin" ? (
                                        <table
                                            className="table align-items-center mb-0"
                                            style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Id
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Currency type
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                        Action
                                                    </th>
                                                    {/* <th className="text-uppercase text-secondary text-xs font-weight-bolder text-center">
                                                        Actions
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            <tbody className="table align-items-center">
                                                {
                                                    isLoading ? (
                                                        <tr>
                                                            <td colSpan="3" className="text-center">
                                                                <span className="loader"></span>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        currencyType?.map((currency, i) => (
                                                            <React.Fragment key={currency.id}>
                                                                <tr>
                                                                    <td>
                                                                        <h6 className="mb-0 ms-2">{currency.id}</h6>
                                                                    </td>
                                                                    <td >
                                                                        <h6 className=" font-weight-bold mb-0 ms-2">{currency.name}</h6>
                                                                    </td> 
                                                                    <td >
                                                                        <span 
                                                                            title="Delete"
                                                                            style={{ color: "red"}}
                                                                            onClick={() => handledelete(currency.id)}>
                                                                            <i className="fa fa-trash fa-lg mt-2 ms-4" aria-hidden="true"></i>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            
                                                            </React.Fragment>
                                                        )
                                                        )
                                                    )
                                                    }
                                            </tbody>
                                        </table>
                                    ) : (
                                     <></>
                                    )}

                               

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</>
  )
}
