import React, { useEffect, useState } from "react";
import Header from "./Header";
// import './Dashboard'
// import '../src/assets/css/material-dashboard.css'
import "../assets/css/material-dashboard.css";

import Sidebar from "./Sidebar";
import { getMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { NavLink } from "react-router-dom";
import { showAlert } from "../service/alert_service";


export default function Dashboard(props) {
  const [total_sum, setTotal_Sum] = useState("");
  const [floting_total, setFloting_Total] = useState("");
  const [date_range, setDate_Range] = useState("");

  const [profit_total, setProfit_Total] = useState("");
  const [equity_total, setEquity_Total] = useState("");
  const [expected_total, setExpected_Total] = useState([]);
  const [finalalldata, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchInput, setSearchInput] = useState("");

  const [sortByFloat, setSortByFloat] = useState("");
  const [sortByEquity, setSortByEquity] = useState("");
  const [sortByBalance, setSortByBalance] = useState("");
  const [sortByProfit, setSortByProfit] = useState("");
  const [currencyName, setCurrencyName] = useState("");
  const [sortByCurrency, setSortByCurrency] = useState("");
  const [sortByMargin, setSortByMargin] = useState("");

  const [currentPage, setCurrentPage] = useState(1)
  const [numbers, setNumbres] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalPage, setTotalPage] = useState();

  const [tstatus, setTStatus] = useState('active');

  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1
    ? JSON.parse(loginUserDetail1)
    : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;

  useEffect(() => {
    const dahsboardata = async () => {
      try {
        const response = await getMethod(`${Apiurl.dashboard_data}?page=${currentPage}&limit=${recordsPerPage}&search=${searchInput}&floating=${sortByFloat}&equity_remaining=${sortByEquity}&account_balance=${sortByBalance}&expected_profit=${sortByProfit}&currency_name=${currencyName}&currency_sort=${sortByCurrency}&margin_level=${sortByMargin}&t_status_filter=${tstatus}`);
        if (response) {
          const numbers = response.data.total_page
          setTotalPage(numbers)
          const numbersArray = Array.from({ length: numbers }, (_, index) => index + 1);
          setNumbres(numbersArray)
          setAllData(response.data.data);

          setProfit_Total(response.data?.currunt_profit?.toFixed(2))
          setFloting_Total(response.data?.floating?.toFixed(2))
          setEquity_Total(response.data?.equity_remaining?.toFixed(2))
          setExpected_Total(response.data?.expected_profit?.toFixed(2))
          setTotal_Sum(response.data?.account_balance?.toFixed(2))
          setDate_Range(response.data?.data[9]?.currunt_daterange)
          setIsLoading(false);
          // const intervalId = setInterval(dahsboardata, 60000); 
          // return () => clearInterval(intervalId);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    dahsboardata();
  }, [currentPage, recordsPerPage, searchInput, sortByFloat, sortByEquity, sortByBalance, sortByProfit, currencyName, sortByCurrency, sortByMargin,tstatus]);

  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const changeCPage = (n) => {
    setCurrentPage(n)
  }
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }

  const handleRecordsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setRecordsPerPage(newValue);
      setCurrentPage(1);
    }
  };

  const handleSearch = (e) => {
    const input = e.target.value.toLowerCase();
    // if(input.length > 2) return;
    setSearchInput(input);
    setCurrentPage(1);

  };


  const handleWhatsapp = (userId) => {
    const usercontact = finalalldata.find((user)=>user.user_id)
    if(usercontact.user.contact_number)
    window.open(`http://wa.me/+91${usercontact.user.contact_number}`)
    else {
      showAlert("No contact number found")
    }
  }


  // const filteredMainUsers = finalalldata?.filter((dashboarddata) =>
  //   dashboarddata.user.name.toLowerCase().includes(searchInput) ||
  //   String(dashboarddata.terminal_id).toLowerCase().includes(searchInput));

  const handleAscSort = (column) => {
    if (column === 'floating') {
      setSortByFloat("asc");
      setSortByEquity("")
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'equity') {
      setSortByFloat("");
      setSortByEquity("asc");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'balance') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("asc")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'profit') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("asc")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'EURUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("EURUSD")
      setSortByCurrency("asc")
      setSortByMargin("")
    } else if (column === 'GBPUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("GBPUSD")
      setSortByCurrency("asc")
      setSortByMargin("")
    } else if (column === 'AUDUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("AUDUSD")
      setSortByCurrency("asc")
      setSortByMargin("")
    } else if (column === 'MARGIN') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("asc")

    }
  }

  const handleDescSort = (column) => {
    if (column === 'floating') {
      setSortByFloat("desc");
      setSortByEquity("")
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'equity') {
      setSortByFloat("");
      setSortByEquity("desc");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'balance') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("desc")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'profit') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("desc")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("")
    } else if (column === 'EURUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("EURUSD")
      setSortByCurrency("desc")
      setSortByMargin("")
    } else if (column === 'GBPUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("GBPUSD")
      setSortByCurrency("desc")
      setSortByMargin("")
    } else if (column === 'AUDUSD') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("AUDUSD")
      setSortByCurrency("desc")
      setSortByMargin("")
    } else if (column === 'MARGIN') {
      setSortByFloat("");
      setSortByEquity("");
      setSortByBalance("")
      setSortByProfit("")
      setCurrencyName("")
      setSortByCurrency("")
      setSortByMargin("desc")
    }
  }

  return (
    <>
      <Header />
      <Sidebar isSidebarOpen={props.isSidebarOpen} />

      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`} style={{ height: '55px', zIndex: "2",overflowX:"hidden" }}>

        <div className="container-fluid pt-4 pe-0" style={{ marginTop: "-45px" }}>
          <div className="row mt-4 d-flex flex-wrap">
            <div className="col-md-12 d-flex flex-wrap gap-1 ps-5" >
              <div
                className="bg-gradient-dark shadow-primary border-radius-lg py-3" style={{ height: "50px", width: '165px' }}>
                <h3
                  style={{ color: "white", margin: "-10px ", textAlign: "center", fontSize: "14px" }}>
                  TOTAL BALANCE : <br />{total_sum}
                </h3>
              </div>

              <div
                className="bg-gradient-dark shadow-primary border-radius-lg py-3" style={{ height: "50px", width: '165px' }}>
                <h3
                  style={{ color: "white", margin: "-10px ", textAlign: "center", fontSize: "14px", width: '165px' }}>
                  TOTAL FLOATING :<br /> {floting_total}
                </h3>
              </div>
              <div
                className="bg-gradient-dark shadow-primary border-radius-lg py-3" style={{ height: "50px", width: '165px' }}>
                <h3
                  style={{ color: "white", margin: "-10px ", textAlign: "center", fontSize: "14px" }}>
                  EXPECTED PROFIT : <br />{expected_total}
                </h3>
              </div>

              <div
                className="bg-gradient-dark shadow-primary border-radius-lg py-3" style={{ height: "50px", width: '165px' }}>
                <h3
                  style={{ color: "white", margin: "-10px ", textAlign: "center", fontSize: "14px" }}>
                  EQUITY REMAINING :<br />{equity_total}
                </h3>
              </div>

              <div
                className="bg-gradient-dark shadow-primary border-radius-lg py-3" style={{ height: "50px", width: '165px' }}>
                <h3
                  style={{ color: "white", margin: "-10px", textAlign: "center", fontSize: "14px" }}>
                  {date_range} <br />
                  {profit_total}
                </h3>
              </div>
            </div>
          </div>

          <div className="container-fluid pt-4">
            <div className="row">
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 ps-4">
                <div className="card my-4">
                  <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                    <div className="bg-info bg-gradient shadow-primary border-radius-lg  d-flex justify-content-between" style={{ height: '55px', zIndex: "5" }}>
                      <h6 className="text-white text-capitalize ps-3 pt-3">
                        DETAIL
                      </h6>
                      {userEmail1 === "superadmin@gmail.com" || userEmail === "Admin" ? (
                        <li className="nav-item d-flex align-items-center">

                          <select
                            className="input rounded ms-1"
                            value={tstatus}
                            onChange={(e) => setTStatus(e.target.value)}
                            style={{ width: "70%", height: "40px", fontSize: "16px",border:"none",outline:"none",marginRight:"25px" }}>
                            <option value="active">Select Status</option>
                            <>
                              <option value="active">active</option>
                              <option value="stop">stop</option>
                              <option value="deleted">delete</option>
                              <option value="need to set bot">need to set bot</option>
                            </>
                          </select>

                          <div className="ms-md-auto pe-md-3 pe-sm-3 d-flex align-items-center">
                            <div className="input-group input-group-outline">
                              <label
                                className="form-label"
                                style={{ color: "white" }}
                              ></label>
                              <input type="text" className="form-control text-white me-1" placeholder="Search here...."
                                value={searchInput} onChange={handleSearch} />
                            </div>
                          </div>
                        </li>

                      ) : (null)
                      }
                    </div>
                  </div>
                  <div className="card-body px-0 pb-2 overflow-x-auto">
                    <div className="p-0" style={{minWidth:"1134px"}}>

                      {userEmail1 === "superadmin@gmail.com" || userEmail === "Admin" ? (
                        <>
                          <table className="table align-items-center mb-0 ">
                            <thead>
                              <tr>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">Terminal Id</th>
                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding">GBPUSD
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByCurrency === "asc" && currencyName === "GBPUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }}
                                          aria-hidden="true" onClick={() => handleAscSort("GBPUSD")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByCurrency === "desc" && currencyName === "GBPUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("GBPUSD")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding">EURUSD
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByCurrency === "asc" && currencyName === "EURUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }}
                                          aria-hidden="true" onClick={() => handleAscSort("EURUSD")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByCurrency === "desc" && currencyName === "EURUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("EURUSD")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding">AUDUSD
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByCurrency === "asc" && currencyName === "AUDUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }}
                                          aria-hidden="true" onClick={() => handleAscSort("AUDUSD")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByCurrency === "desc" && currencyName === "AUDUSD" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("AUDUSD")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">User Name</th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding"><span className="d-flex flex-column"><div>MARGIN</div><div>LEVEL</div> </span>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByMargin === "asc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }}
                                          aria-hidden="true" onClick={() => handleAscSort("MARGIN")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByMargin === "desc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("MARGIN")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding">Floating
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByFloat === "asc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }}
                                          aria-hidden="true" onClick={() => handleAscSort("floating")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByFloat === "desc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("floating")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>



                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding"><span className="d-flex flex-column"><div>Equity</div><div>Remaining</div> </span>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByEquity === "asc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleAscSort("equity")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByEquity === "desc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("equity")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding"><span className="d-flex flex-column"><div>Account</div><div>balance</div> </span>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByBalance === "asc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleAscSort("balance")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByBalance === "desc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("balance")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>

                                <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                  <div className="ascding"><span className="d-flex flex-column"><div>Expected</div><div>profit</div> </span>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <div>
                                        <i className={`fa fa-sort-up ${sortByProfit === "asc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleAscSort("profit")}></i>
                                      </div>
                                      <div style={{ marginTop: "-10px" }}>
                                        <i className={`fa fa-sort-down ${sortByProfit === "desc" ? "highlight" : ""}`} style={{ fontSize: "20px", cursor: "pointer" }} aria-hidden="true"
                                          onClick={() => handleDescSort("profit")}></i>
                                      </div>
                                    </div>
                                  </div>
                                </th>
                              </tr>
                            </thead>

                            <tbody className="table align-items-center">
                              {isLoading ? (
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    <span className="loader"></span>
                                  </td>
                                </tr>
                              ) : (
                                finalalldata?.map((dashboarddata, index) => (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>
                                        <NavLink to={`/activeposition/${dashboarddata.terminal_id}`} target="_blank">{" "}
                                          <h6
                                            className=" font-weight-bold mb-0 ms-1"
                                            style={{ cursor: "pointer", color: getStatusColor(dashboarddata.t_status) }}>
                                            {dashboarddata?.terminal_id}
                                          </h6>
                                        </NavLink>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {dashboarddata?.GBPUSD}
                                          {dashboarddata?.user_currencies?.find((c) => c.currency.name === "GBPUSD")?.count
                                            ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "GBPUSD")?.count}]` : ''}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {dashboarddata?.EURUSD}
                                          {dashboarddata?.user_currencies?.find((c) => c.currency.name === "EURUSD")?.count
                                            ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "EURUSD")?.count}]` : ''}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">
                                          {dashboarddata?.AUDUSD}
                                          {dashboarddata?.user_currencies?.find((c) => c.currency.name === "AUDUSD")?.count
                                            ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "AUDUSD")?.count}]` : ''}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0">{dashboarddata?.user?.name}</h6>
                                      </td>
                                      <td style={{ display: 'flex', alignItems: 'center',justifyContent:'space-between' }}>
                                        <h6 className="font-weight-bold mb-0 ms-2">{parseInt(dashboarddata?.margin_level)}</h6>
                                        <i className="fa fa-whatsapp" style={{color:"green"}}  onClick={()=>handleWhatsapp(dashboarddata.user_id)} aria-hidden="true"></i>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.floating}</h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.equity_remaining}</h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.account_balance}
                                        </h6>
                                      </td>
                                      <td>
                                        <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.expected_profit}</h6>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                ))
                              )}
                            </tbody>
                          </table>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div className="" style={{ border: '', padding: '5px', borderRadius: '5px', marginTop: '45px' }}>
                              <label htmlFor="recordsPerPage">Records per Page:</label>
                              <select
                                id="recordsPerPage"
                                value={recordsPerPage}
                                onChange={handleRecordsPerPageChange}>
                                <option value="100">100</option>
                                <option value="150">150</option>
                                <option value="200">200</option>
                              </select>
                            </div>

                            <nav className="mt-5 me-3 d-flex justify-content-end">
                              <ul className="pagination">
                                <li className="page-item">
                                  <a href="#" className="page-link" onClick={prePage}>prev</a>
                                </li>

                                {
                                  numbers.map((n, i) => (
                                    <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                      <a href="#" className="page-link" onClick={() => changeCPage(n)}>{n}</a>
                                    </li>
                                  ))
                                }

                                <li className={`page-item ${currentPage === totalPage ? 'disabled' : ''}`}>
                                  <a href="#" className="page-link" onClick={nextPage}>next</a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </>

                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">Terminal Id</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">GBPUSD</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">EURUSD</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">AUDUSD</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">User Name</th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                <div className="ascding">Floating </div>
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                <div className="ascding">Equity Remaining</div>
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                <div className="ascding">Account Balance</div>
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                <div className="ascding">Expected Profit</div>
                              </th>
                            </tr>
                          </thead>

                          <tbody className="table align-items-center">
                            {isLoading ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span className="loader"></span>
                                </td>
                              </tr>
                            ) : (
                              finalalldata?.map((dashboarddata, index) => (
                                <React.Fragment key={index}>
                                  <tr>
                                    <td>
                                      <NavLink to={`/activeposition/${dashboarddata.terminal_id}`} target="_blank">{" "}
                                        <h6
                                          className=" font-weight-bold mb-0"
                                          style={{ cursor: "pointer", color: getStatusColor(dashboarddata.t_status) }}>
                                          {dashboarddata?.terminal_id}
                                        </h6>
                                      </NavLink>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0 ms-2">
                                        {dashboarddata?.GBPUSD}
                                        {dashboarddata?.user_currencies?.find((c) => c.currency.name === "GBPUSD")?.count
                                          ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "GBPUSD")?.count}]` : ''}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0">
                                        {dashboarddata?.EURUSD}
                                        {dashboarddata?.user_currencies?.find((c) => c.currency.name === "EURUSD")?.count
                                          ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "EURUSD")?.count}]` : ''}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0 ms-2">
                                        {dashboarddata?.AUDUSD}
                                        {dashboarddata?.user_currencies?.find((c) => c.currency.name === "AUDUSD")?.count
                                          ? ` [${dashboarddata?.user_currencies.find((c) => c.currency.name === "AUDUSD")?.count}]` : ''}
                                      </h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0">{dashboarddata?.user?.name}</h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0">{dashboarddata?.floating}</h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.equity_remaining}</h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.account_balance}</h6>
                                    </td>
                                    <td>
                                      <h6 className="font-weight-bold mb-0 ms-2">{dashboarddata?.expected_profit}</h6>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              ))
                            )}
                          </tbody>
                        </table>
                      )}



                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
