import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Header from './Header'
import { getMethod, postMethod } from '../service/base_service';
import { Apiurl } from '../service/apiRoute';
import { showAlert } from '../service/alert_service';
import { useNavigate } from 'react-router-dom';

export default function Changepassword(props) {

  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState("0");



  const fetchUsers = async () => {

    try {

      const response = await getMethod(Apiurl.user);

      // Make an API request to fetch user data
      if (response.data) {
        setUsers(response.data);
      }
    } catch (error) {
    }

  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const navigate = new useNavigate()

  const handlechange = (item, e) => {


    if (item === "newpassword") {
      setNewpassword(e.target.value);
    }
    else if (item === "confirmpassword") {
      setConfirmpassword(e.target.value);
    }
    else if (item === "user") {
      setUser(e.target.value);
    }


  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (newpassword === "") {
      showAlert("please input new password.", "error");
    } else if (confirmpassword === "") {
      showAlert("please input confirm password.", "error");
    } else if (newpassword !== confirmpassword) {
      showAlert("Your confirm password is not match with your new password. Please try again.", "error");
    } else {
      const params = {
        password: newpassword,
        userId: user
      };
      const response = await postMethod(Apiurl.changepassword, params);

      if (response.success) {
        showAlert(response.message, "success");
        setTimeout(() => {
          navigate("/");
        }, 1000)
      } else {
        showAlert(response.message, "error");
      }
    }
  }

  return (
    <>
      <Header />
      <Sidebar isSidebarOpen={props.isSidebarOpen} />
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
        <div className="container-fluid py-4">
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                    <h6 className="text-white text-capitalize ps-3 mt-3">
                      CHANGE PASSWORD
                    </h6>
                  </div>
                </div>
                <div className="container my-4">
                  <div className="row">
                    <div className="col-lg-4 col-md-8 col-12 mx-auto">
                      <div className="card z-index-0 fadeIn3 fadeInBottom">
                        <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">

                        </div>

                        <div className="card-body">
                          <form role="form" className="text-start" onSubmit={handleSubmit}>
                            <div className="box mt-2">
                              <label style={{ fontSize: "16px", display: "block" }}>
                                User
                              </label>
                              <div className="box">
                                <select
                                  className="input rounded"
                                  value={user}
                                  onChange={(event) => handlechange("user", event)}
                                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                                >
                                  <option value="0" disabled>Select User</option>

                                  {
                                    users && (users?.map((user, index) =>
                                      (<option key={index} value={user.id}>{user.name}</option>)
                                    ))
                                  }

                                  {/* <option value="USD">USD</option>
                    <option value="INR">INR</option> */}
                                </select>
                              </div>
                            </div>
                            <label className="form-label mt-2 mb-0" style={{ marginTop: '10px' }}>New Password</label>
                            <div className="input-group input-group-outline">
                              <input type="text" className="form-control" value={newpassword} onChange={(event) => handlechange("newpassword", event)} />
                            </div>
                            <label className="form-label mt-2 mb-0">Confirm Password</label>
                            <div className="input-group input-group-outline">
                              <input type="text" className="form-control" value={confirmpassword} onChange={(event) => handlechange("confirmpassword", event)} />

                            </div>

                            <div className="text-center">
                              <button type="submit" className="btn bg-info bg-gradient w-100 my-4 mb-2 text-white">Submit</button>
                            </div>


                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
    </>
  )
}
