import React, { useEffect, useState } from 'react'
import { postMethod } from '../service/base_service';
import { Apiurl } from '../service/apiRoute';
import { showAlert } from '../service/alert_service';
import { Cookies  } from 'react-cookie';

export default function Login() {

  const cookies = new Cookies();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const token =  cookies.get("token");
    if (token) {
      window.location.href = "/";
    }
  }, []);

  const handlechange = (item, e) => {
    if (item === "email") {
      setEmail(e.target.value);
    }
    else if (item === "password") {
      setPassword(e.target.value);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const params = {
      email: email,
      password: password,
    };

    const response = await postMethod(Apiurl.login, params);
    if (response?.success) {
      showAlert(response.message, "success");
      // localStorage.setItem("token", response.data["token"]);
      cookies.set("token", response.data.token, {maxAge: 86400});
      localStorage.setItem("user", JSON.stringify(response.data.admin || response.data.register));
      // setTimeout(() => {
      //   localStorage.clear();
      //   cookies.remove("token");
      //   window.location.href = "/login";
      // }, 30000);
      window.location.href = "/";
    } else {
      showAlert(response?.message ? response.message : "Something went wrong", "error");
    }
  }

  return (
    <>
      <main className="main-content  mt-0">
        <div className="page-header align-items-start min-vh-100" style={{ backgroundImage: "url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80')" }}>
          <span className="mask bg-gradient-dark opacity-6"></span>
          <div className="container my-auto">
            <div className="row">
              <div className="col-lg-4 col-md-8 col-12 mx-auto">
                <div className="card z-index-0 fadeIn3 fadeInBottom">
                  <div className="card-body">
                    <form role="form" className="text-start" onSubmit={handleSubmit}>
                      <label className="form-label" style={{ marginTop: '10px' }}>Email</label>
                      <div className="input-group input-group-outline my-3">
                        <input type="email" className="form-control" value={email} onChange={(event) => handlechange("email", event)} />
                      </div>

                      <div>
                        <label className="form-label" >Password</label>
                        <div className="input-group input-group-outline mb-3">
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control" style={{ position: "relative" }} value={password}
                            onChange={(event) => handlechange("password", event)} />
                        </div>
                        <span
                          className="password-toggle-button"
                          style={{
                            zIndex:'9',
                            position: "absolute",              
                            cursor: "pointer",
                            border: "none",
                            background: "none",
                            bottom: "120px",
                            right:"33px",
                          }}
                          onClick={() => setShowPassword(!showPassword)}>
                          {showPassword ? (
                            <i className="fa fa-eye-slash" aria-hidden="true"></i>
                          ) : (
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          )}
                        </span>
                      </div>

                      <div className="text-center">
                        <button type="submit" className="btn bg-gradient-primary w-100 my-4 mb-2">Sign in</button>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </main>
    </>
  )
}
