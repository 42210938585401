import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { Modal, ModalHeader } from "reactstrap";
import { deleteMethod, getMethod, postMethod, putMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Showdilog, showAlert, showConfirmationDialog } from "../service/alert_service";
import "./Loader.css";
import './Userdetail.css';
import { NavLink } from "react-router-dom";

function T_Pc(props) {
    const loginUserDetail = localStorage?.getItem("user");
    const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
    const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

    const loginUserDetail1 = localStorage?.getItem("user");
    const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
    const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;

    const [isLoading, setIsLoading] = useState(true);

    const [modal, setModal] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [id, setId] = useState();
    const [name, setName] = useState("");
    const [ip, setIp] = useState("");

    const [tpc, setTpc] = useState();
    const [selectedTpcId, setSelectedTpcId] = useState();


    const [errors, setErrors] = useState({});
    const [searchInput, setSearchInput] = useState("");


    const [selectedTpc, setSelectedTpc] = useState("");
    const [showPassword2, setShowPassword2] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(50000);


    useEffect(() => {
        fetchTpc();
    }, []);

    const fetchTpc = async () => {
        try {
            const response = await getMethod(`${Apiurl.t_Pc}?page=${currentPage}&limit=${recordsPerPage}`);
            if (response) {
                setIsLoading(false)
            }
            setTpc(response.data.data);
        } catch (error) {
        }
    };

    const setModalOpen = (() => {
        setModal(true);
        setName(null)
        setErrors('')
    })

    const handleSearch = (e) => {
        const input = e.target.value.toLowerCase();
        setSearchInput(input);
    };


    const handlechange = (item, e) => {
        const stateSetters = {
            name: setName,
            ip: setIp
        };
        const setter = stateSetters[item];
        if (setter) {
            setter(e.target.value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!name) {
            errors.name = "Please Enter T_Pc";
        }
        if (!ip) {
            errors.ip = "Please Enter IP";
        }

        setErrors(errors);
        if (Object.keys(errors).length !== 0) {
            showAlert("Please enter proper data.", "error");
            return;
        }
        const data = {
            name: name,
            ip: ip
        };
        try {
            setIsLoading(true);
            setTimeout(async () => {
                const response = await postMethod(Apiurl.t_Pc, data);
                if (response.success) {
                    Showdilog("Add T_Pc successful");
                    setModal(!modal)
                    fetchTpc()
                } else {
                    Showdilog(response.message, "Add T_Pc failed");
                }
            }, 1000);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const handleEditSubmit = async (event) => {
        event.preventDefault();
        const errors = {};
        if (!name) {
            errors.name = "Please Enter T_Pc";
        }
        if (!ip) {
            errors.name = "Please Enter IP";
        }

        setErrors(errors);
        if (Object.keys(errors).length !== 0) {
            showAlert("Please enter proper data.", "error");
            return;
        }

        const data = {
            name: name,
            ip: ip
        };
        try {
            setIsLoading(true);
            setTimeout(async () => {
                const response = await putMethod(`${Apiurl.t_Pc}/${selectedTpc.id}`, data);
                if (response) {
                    Showdilog("Edit T_Pc successful");
                    setModalEdit(!modalEdit)
                    fetchTpc()

                } else {
                    Showdilog(response.message, "Edit T_Pc failed");
                }
            }, 1000);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const useredite = (id) => {
        setModalEdit(true);
        const UserData = tpc.find((user) => user.id === id);
        setSelectedTpc(UserData)
        setId(UserData.id)
        setName(UserData.name)
        setIp(UserData.ip)
    };

    const handledelete = async (id) => {
        const confirmation = await showConfirmationDialog("Are you sure you want to delete ?", "", "delete");
        if (confirmation) {
            const response = await deleteMethod(`${Apiurl.t_Pc}/${id}`);
            if (response.success) {
                Showdilog(response.message, "success");
                fetchTpc();
            } else {
                Showdilog(response.message, "error");
            }
        }
    };



    const handleRowClick = (tpc) => {

        setSelectedTpcId(tpc.id);

        if (selectedTpcId && selectedTpcId === tpc.id) {
            setSelectedTpcId(null);
        } else {
            setSelectedTpcId(tpc.id);
        }
    }

    const getStatusColor = (tstatus) => {
        if (tstatus === "active") {
          return "green";
        } else if (tstatus === "stop") {
          return "yellow";
        } else if (tstatus === "deleted") {
          return "red";
        }
      };


      const handlePasswordClick2 = (mainuserId) => {
        setShowPassword2((prevShowPassword2) => ({
          ...prevShowPassword2,
          [mainuserId]: !prevShowPassword2[mainuserId],
        }));
      };

      

    return (
        <>
            <Header />
            <Sidebar isSidebarOpen={props.isSidebarOpen} />
            <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>

                {/* Add tpc modal */}

                <Modal size="ig" isOpen={modal} toggle={() => setModal(!modal)}>
                    <ModalHeader toggle={() => setModal(!modal)}>
                        <form style={{ margin: "10px 70px" }} className="login" onSubmit={handleSubmit}>
                            <div className="box" style={{}}>
                                <label style={{ fontSize: "16px", display: "block", marginBottom: "0px" }}>
                                    T_Pc <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded"
                                    value={name}
                                    placeholder="Enter T_Pc"
                                    onChange={(event) => handlechange("name", event)}
                                    style={{ width: "100%", height: "40px", fontSize: "16px", marginBottom: "3px" }} />
                                {errors?.name && (
                                    <p style={{ marginBottom: "3px", fontSize: "14px" }} className="errorMsg text-danger text-bold">{errors?.name}</p>
                                )}
                                <label style={{ fontSize: "16px", display: "block", marginBottom: "0px" }}>
                                    IP <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded"
                                    value={ip}
                                    placeholder="Enter IP"
                                    onChange={(event) => handlechange("ip", event)}
                                    style={{ width: "100%", height: "40px", fontSize: "16px" }} />
                                {errors?.ip && (
                                    <p style={{ marginBottom: "3px", fontSize: "14px" }} className="errorMsg text-danger text-bold">{errors?.ip}</p>
                                )}
                            </div>

                            <div className="modal-footer d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-secondary "
                                    data-bs-dismiss="modal"
                                    onClick={() => setModal(!modal)}>Close
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isLoading}>
                                    {isLoading ? (<span className="loader" style={{ zIndex: "10000" }}></span>) : ("Submit")}
                                </button>
                            </div>
                        </form>
                    </ModalHeader>
                </Modal>

                {/* Edit modal */}

                <Modal
                    size="ig"
                    isOpen={modalEdit}
                    toggle={() => setModalEdit(!modalEdit)}>
                    <ModalHeader toggle={() => setModalEdit(!modalEdit)}>
                        <form style={{ margin: "10px 70px" }} className="login" onSubmit={handleEditSubmit}>
                            <div className="box" style={{}}>
                                <label style={{ fontSize: "16px", display: "block", marginBottom: "0px" }}>
                                    T_Pc <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                </label>

                                <input
                                    type="text"
                                    className="input rounded"
                                    value={name}
                                    placeholder="Enter T_Pc"
                                    onChange={(event) => handlechange("name", event)}
                                    style={{ width: "100%", height: "40px", fontSize: "16px", marginBottom: "3px" }} />
                                {errors.name && (
                                    <p style={{ marginBottom: "3px", fontSize: "14px" }} className="errorMsg text-danger text-bold">{errors.name}</p>
                                )}
                                <label style={{ fontSize: "16px", display: "block", marginBottom: "0px" }}>
                                    IP <span className="text-danger" style={{ margin: "4px" }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    className="input rounded"
                                    value={ip}
                                    placeholder="Enter IP"
                                    onChange={(event) => handlechange("ip", event)}
                                    style={{ width: "100%", height: "40px", fontSize: "16px" }} />
                                {errors?.ip && (
                                    <p style={{ marginBottom: "3px", fontSize: "14px" }} className="errorMsg text-danger text-bold">{errors?.ip}</p>
                                )}
                            </div>

                            <div className="modal-footer d-flex justify-content-between">
                                <button
                                    type="button"
                                    className="btn btn-secondary "
                                    data-bs-dismiss="modal"
                                    onClick={() => setModalEdit(!modalEdit)}> Close
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary"
                                    disabled={isLoading}>
                                    {isLoading ? (<span className="loader" style={{ zIndex: "10000" }}></span>) : ("Submit")}
                                </button>
                            </div>
                        </form>
                    </ModalHeader>
                </Modal>

                <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
                    <div className="row">
                        <div className="col-12">
                            <div className="card my-4">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                                    <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                                        <h6 className="text-white text-capitalize ps-3">
                                            <li className="nav-item d-flex align-items-center">
                                                {/* <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                                                    <div className="input-group input-group-outline mt-2">
                                                        <input
                                                            type="text"
                                                            className="form-control text-white"
                                                            placeholder="Search here...."
                                                            value={searchInput}
                                                            onChange={handleSearch}
                                                        />
                                                    </div>
                                                </div> */}
                                            </li>
                                        </h6>

                                        {userEmail1 === "superadmin@gmail.com" ||
                                            userEmail === "Admin" ? (
                                            <li className="nav-item d-flex align-items-center">
                                                <button
                                                    onClick={() => setModalOpen()}
                                                    className="btn btn-outline-dark d-flex mt-3 me-3"
                                                    style={{ color: "white", border: "1px solid white" }}>
                                                    <i className="fa fa-user-plus fa-lg mt-1 me-1" style={{ color: "white" }} aria-hidden="true"></i>
                                                    Add T_Pc
                                                </button>
                                            </li>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                                <div className="card-body px-0 pb-2">
                                    <div className="table-responsive p-0">
                                        {
                                            userEmail1 === `superadmin@gmail.com` ||
                                                userEmail === "Admin" ? (
                                                <table
                                                    className="table align-items-center mb-0"
                                                    style={{ width: "100%" }}>
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                Id
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                Name
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                Ip
                                                            </th>
                                                            <th className="text-uppercase text-secondary text-xs font-weight-bolder text-center">
                                                                Actions
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table align-items-center">
                                                        {
                                                            isLoading ? (
                                                                <tr>
                                                                    <td colSpan="3" className="text-center">
                                                                        <span className="loader"></span>
                                                                    </td>
                                                                </tr>
                                                            ) : (
                                                                tpc?.map((tpc, i) => (
                                                                    <React.Fragment key={tpc.id}>
                                                                        <tr>
                                                                            <td
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => handleRowClick(tpc)}>
                                                                                <i
                                                                                    className={`fa ${selectedTpcId && selectedTpcId === tpc.id ? "fa-arrow-down" : "fa-arrow-right"}`}
                                                                                    aria-hidden="true"
                                                                                />
                                                                            </td>
                                                                            <td>
                                                                                <h6 className="mb-0">{tpc.id}</h6>
                                                                            </td>
                                                                            <td >
                                                                                <h6 className=" font-weight-bold mb-0">{tpc.name}</h6>
                                                                            </td>
                                                                            <td >
                                                                                <h6 className=" font-weight-bold mb-0">{tpc.ip}</h6>
                                                                            </td>
                                                                            <td >
                                                                                <span
                                                                                    title="Edit"
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#exampleModal"
                                                                                    style={{ color: "green", marginLeft: '38%' }}
                                                                                    onClick={() => useredite(tpc.id)}>
                                                                                    <i className="fa fa-pencil-square-o fa-lg ms-4" aria-hidden="true"></i>
                                                                                </span>
                                                                                <span
                                                                                    title="Delete"
                                                                                    style={{ color: "red" }}
                                                                                    onClick={() => handledelete(tpc.id)}>
                                                                                    <i className="fa fa-trash fa-lg mt-2 ms-4" aria-hidden="true"></i>
                                                                                </span>
                                                                            </td>
                                                                        </tr>

                                                                        {
                                                                            selectedTpcId &&
                                                                            selectedTpcId === tpc.id && (
                                                                                <>
                                                                                    <tr>
                                                                                        <td colSpan={6}>
                                                                                            <table
                                                                                                className="table table-striped table-bordered "
                                                                                                style={{ width: "100px" }}>
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            User Name
                                                                                                        </th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Terminal Id
                                                                                                        </th>
                                                                                                        <th
                                                                                                            colSpan={2}
                                                                                                            className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Terminal Password
                                                                                                        </th>
                                                                                                        <th style={{ borderColor: "white" }}></th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Terminal Password Only Read
                                                                                                        </th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Entry Date
                                                                                                        </th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            T PC
                                                                                                        </th>
                                                                                                        {/* <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Commission
                                                                                                        </th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Currency
                                                                                                        </th>
                                                                                                        <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                                                                            Actions
                                                                                                        </th> */}
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {selectedTpcId && tpc.user_mains?.map((list) => (
                                                                                                        <tr key={list.terminal_id}>
                                                                                                            <td style={{ cursor: "pointer" }}>
                                                                                                                <h6 className=" font-weight-bold mb-0 ms-3">{list.user.name}</h6>
                                                                                                            </td>
                                                                                                            <td>
                                                                                                               
                                                                                                                    <h6
                                                                                                                        className=" font-weight-bold mb-0 ms-3"
                                                                                                                        style={{
                                                                                                                            cursor: "pointer",
                                                                                                                            color: getStatusColor(list.t_status)
                                                                                                                        }}
                                                                                                                        >
                                                                                                                        {list?.terminal_id}
                                                                                                                    </h6>
                                                                                                               
                                                                                                            </td>
                                                                                                            <td colSpan={2}>
                                                                                                                <h6 className="font-weight-bolder mb-0 ms-3">
                                                                                                                    {showPassword2[list.terminal_id]
                                                                                                                        ? list.terminal_pass
                                                                                                                        : list.terminal_pass !== undefined
                                                                                                                            ? list.terminal_pass.replace(/.(?=.{0})/g, "*") : ""}
                                                                                                                </h6>
                                                                                                            </td>
                                                                                                            <td className="ms-n5">
                                                                                                                <i
                                                                                                                    className={`fa ${showPassword2[list.terminal_id] ? "fa-eye-slash" : "fa-eye"} password-icon`}
                                                                                                                    onClick={() => handlePasswordClick2(list.terminal_id)} />
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <h6 className="font-weight-bolder mb-0 ms-4">
                                                                                                                    {list.terminal_read_only_password}
                                                                                                                </h6>
                                                                                                            </td>

                                                                                                            <td className="d-flex flex-column justify-content-center">
                                                                                                                <h6 className=" font-weight-bold mb-0 ms-2">
                                                                                                                    {new Date(list.entrydate).toLocaleDateString("en-GB")}{" "}
                                                                                                                    {/* {new Date(list.entrydate).toLocaleTimeString("en-US" )} */}
                                                                                                                </h6>
                                                                                                            </td>

                                                                                                            <td>
                                                                                                                <h6 className="font-weight-bolder mb-0 ms-3 "> {list.t_pc_id}</h6>
                                                                                                            </td>
                                                                                                            {/* <td>
                                                                                                                <span
                                                                                                                    title="Add commision"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal">
                                                                                                                    <i
                                                                                                                        className="fa fa-plus-circle fa-lg mt-2 me-2"
                                                                                                                        onClick={() => AddmodalCommission(list)}
                                                                                                                        style={{ color: "green", cursor: "pointer", marginLeft: "33px" }} aria-hidden="true">
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            </td> */}

                                                                                                            {/* <td>
                                                                                                                <span
                                                                                                                    title="Add Currency"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal"
                                                                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                                                                    onClick={() => addCurrency(user?.id, list?.terminal_id)}
                                                                                                                >
                                                                                                                    <i
                                                                                                                        className="fa fa-sharp fa-regular fa-coins fa-lg mt-2 ms-4"
                                                                                                                        aria-hidden="true"
                                                                                                                    ></i>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    title="Edit Currency"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal"
                                                                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                                                                    onClick={() => editCurrency(user?.id, list?.terminal_id)}
                                                                                                                    >
                                                                                                                    <i className="fa fa-pencil fa-lg mt-2" aria-hidden="true"></i>
                                                                                                                    </span>
                                                                                                            </td> */}
{/* 
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    title="Add file"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal"
                                                                                                                    style={{ color: "orange", cursor: "pointer" }}
                                                                                                                    onClick={() => FileUploadModal(user?.id, list?.terminal_id)}
                                                                                                                >
                                                                                                                    <i
                                                                                                                        className="fa fa-upload fa-lg mt-2 me-2 me-4"
                                                                                                                        aria-hidden="true"
                                                                                                                    ></i>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    title="Download file"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal"
                                                                                                                    style={{ color: "red", cursor: "pointer" }}
                                                                                                                    onClick={() => FileDownloadModal(user?.id, list?.terminal_id)}
                                                                                                                >
                                                                                                                    <i
                                                                                                                        className="fa fa-download fa-lg mt-2 me-2 me-4"
                                                                                                                        aria-hidden="true"
                                                                                                                    ></i>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    title="Edit terminal"
                                                                                                                    data-bs-toggle="modal"
                                                                                                                    data-bs-target="#exampleModal"
                                                                                                                    style={{ color: "green", cursor: "pointer" }}
                                                                                                                    onClick={() => AddModalAddAcount1(list?.terminal_id)}
                                                                                                                >
                                                                                                                    <i
                                                                                                                        className="fa fa-pencil-square-o fa-lg mt-2 me-2 me-4"
                                                                                                                        aria-hidden="true"
                                                                                                                    ></i>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    title="Delete"
                                                                                                                    style={{ color: "red", cursor: "pointer" }}
                                                                                                                    onClick={() => handleterminaldelete(list.terminal_id)}>
                                                                                                                    <i
                                                                                                                        className="fa fa-trash fa-lg mt-2"
                                                                                                                        aria-hidden="true">
                                                                                                                    </i>
                                                                                                                </span>
                                                                                                            </td> */}
                                                                                                        </tr>
                                                                                                    )
                                                                                                    )}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            )}

                                                                    </React.Fragment>
                                                                ))
                                                            )}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <></>
                                            )}



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}
export default T_Pc