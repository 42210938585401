import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { getMethod, postMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Showdilog, showAlert } from "../service/alert_service";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { NavLink, useNavigate } from "react-router-dom";

export default function TerminalAccount(props) {
  const [selectedUser, setSelectedUser] = useState({});
  const [addmainusers, setMainUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [showPassword2, setShowPassword2] = useState({});
  const [modalamount, setAddamount] = useState(false);
  const [modalbalance, setAddbalance] = useState(false);
  const [showAddBalance, setShowAddBalance] = useState(false);
  const [amount, setAddterminalamount] = useState("");
  const [terminalId, setTerminalId] = useState("");
  const [errors, setErrors] = useState({});

  const [usercommission, setCommission] = useState([]);


  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role : null;

  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null;
  const [isLoading, setIsLoading] = useState(true);

  const [balanceamount, setBalance] = useState("");
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [balance, setBalanceamount] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [numbers, setNumbres] = useState([]);
  const [recordsPerPage, setRecordsPerPage] = useState(50);
  const [totalPage, setTotalPage] = useState();
  
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMainUsers = async () => {
      try {
        const response = await getMethod(`${Apiurl.getTemrminals}?page=${currentPage}&limit=${recordsPerPage}&search=${searchInput}`);
        if (response) {
        const numbers = response.data.total_page
        setTotalPage(numbers)
        const numbersArray = Array.from({ length: numbers }, (_, index) => index + 1);
        setNumbres(numbersArray)
        setMainUsers(response.data.data);
        setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
      }
    };
    fetchMainUsers();
  }, [currentPage, recordsPerPage,searchInput]);



  const handlePasswordClick2 = (mainuserId) => {
    setShowPassword2((prevShowPassword2) => ({
      ...prevShowPassword2,
      [mainuserId]: !prevShowPassword2[mainuserId],
    }));
  };

  const handleSearch = (e) => {
    const input = e.target.value.toLowerCase();
    setSearchInput(input);
    setCurrentPage(1)
  };


  const Addbalance = (terminalId) => {
    setAddbalance(true);
    setTerminalId(terminalId);
  };
  const AddmodalTerminalAmount = (terminalId) => {
    setAddamount(true);
    setTerminalId(terminalId);
  };
  const adduserbalanceechange = (field, event) => {
    event.preventDefault();
    const stateSetters = {
      balanceamount: setBalance,
    };

    const setter = stateSetters[field];
    if (setter) {
      setter(event.target.value);
    }
  };

  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };


  const Addbalancesubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    if (!balanceamount) {
      errors.balanceamount = "Please Enter  Amount";
    }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      showAlert("Please enter proper data.", "error");
      return;
    }

    const res = {
      terminal_id: terminalId,
      amount: balanceamount,
    };

    const response = await postMethod(Apiurl.userbalance, res);
    if (response.success) {
      if (response.success) {
        Showdilog("Balance added successfully.");

        navigate("/balance");
        setBalance("");
        setTerminalId("");
      } else {
        Showdilog(response.message, "failed");
      }
    }
  };

  const Withdrawbalancesubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    if (!withdrawAmount) {
      errors.withdrawAmount = "Please Enter  Amount";
    }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      showAlert("Please enter proper data.", "error");
      return;
    }

    const params = {
      terminal_id: terminalId,
      amount: withdrawAmount,
    };

    const response = await postMethod(Apiurl.userbalancewithdraw, params);
    if (response.success) {
      Showdilog("Balance withdraw successfully.");
      navigate("/balance");
      setWithdrawAmount("");
      setTerminalId("");
    } else {
      Showdilog(response.message, "failed");
    }
  };

  const addterminalamounthandlechange = (field, event) => {
    event.preventDefault();
    const stateSetters = {
      amount: setAddterminalamount,
    };

    const setter = stateSetters[field];
    if (setter) {
      setter(event.target.value);
    }
  };

  const AddTerminalAmountsubmit = async (event) => {
    event.preventDefault();
    const errors = {};
    if (!amount) {
      errors.amount = "Please Enter Amount";
    }
    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      showAlert("Please enter proper data.", "error");
      return;
    }
    const res = {
      terminal_id: terminalId,
      amount,
    };
    const response = await postMethod(Apiurl.terminalamount, res);
    if (response.success) {
      if (response.success) {
        Showdilog("Amount added successfully.");
        navigate("/terminalaccounts");
        //  window.location.href='/wallet';
        setAddamount(false);
        setAddterminalamount("");
        setTerminalId("");
      } else {
        Showdilog(response.message, "failed");
        setAddamount(false);
      }
    }
  };

  const handleRowClick = async (user) => {
    setSelectedUser(user);
    try {
      if (selectedUser && selectedUser.terminal_id === user.terminal_id) {
        setSelectedUser(null);
        const response = await getMethod(`${Apiurl.accountcommistions}?terminalId=${user.termimnal_id}`);
        if (response) {
          setSelectedUser(null)
        }
        const data = response;
        setCommission(data);
      } else {
        setCommission(user.account_commistions);
      }
    } catch (error) {
    }
  };

  const prePage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
  }
  const changeCPage = (id) => {
    setCurrentPage(id)
  }
  const nextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  const handleRecordsPerPageChange = (event) => {
    const newValue = parseInt(event.target.value, 10);
    if (!isNaN(newValue) && newValue > 0) {
      setRecordsPerPage(newValue);
      setCurrentPage(1);
    }
  };

  return (
    <>
      <Header />
      <Sidebar isSidebarOpen={props.isSidebarOpen} />
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>

        <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{ height: "55px" }}>
                    <h6 className="text-white text-capitalize ps-3 mt-3">
                      Terminal DETAIL
                    </h6>
                    {userEmail1 === "superadmin@gmail.com" || userEmail === "Admin" ? (
                    <li className="nav-item d-flex align-items-center">
                      <div className="ms-md-auto pe-md-3 d-flex align-items-center">
                        <div className="input-group input-group-outline">
                          <label
                            className="form-label"
                            style={{ color: "white" }}
                          ></label>
                          <input
                            type="text"
                            className="form-control text-white"
                            placeholder="Search here...."
                            value={searchInput}
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                    </li>
                    ) : (null) 
                    }
                  </div>
                </div>
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    {isLoading ? (
                      <div className="loader"></div>
                    ) :
                      userEmail1 === "superadmin@gmail.com" || userEmail === "Admin" ? (
                        <>
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                #
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                User Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder" >
                                Terminal Id
                              </th>
                              <th
                                colSpan={2}
                                className="text-uppercase text-secondary text-xs font-weight-bolder"
                              >
                                Terminal Password
                              </th>
                              <th></th>
                              {/* <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                           Balance
                          </th> */}

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Entry Date
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Amount
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Balance
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table align-items-center">
                          
                            { addmainusers.length > 0 && addmainusers?.map(
                              (mainuser, i) =>
                                mainuser.user.status !== "Deactive" && (
                                  <React.Fragment key={mainuser.terminal_id}>
                                    <tr>
                                      <td
                                        style={{ cursor: "pointer" }}
                                        onClick={async () => { await handleRowClick(mainuser) }}>
                                        <i
                                          className={`fa ${selectedUser?.terminal_id ===
                                            mainuser?.terminal_id
                                            ? "fa-arrow-down"
                                            : "fa-arrow-right"
                                            }`}
                                          aria-hidden="true"
                                        />
                                      </td>

                                      <td>
                                        <h6 className=" font-weight-bold mb-0 ">
                                          {mainuser.user.name}
                                        </h6>
                                      </td>
                                      <td>
                                        <NavLink to={`/activeposition/${mainuser.terminal_id}`} target="_blank"><h6 className=" font-weight-bold mb-0" style={{ cursor: "pointer", color: getStatusColor(mainuser.t_status), }} >
                                          {mainuser.terminal_id}
                                        </h6>
                                        </NavLink>
                                      </td>
                                      <td colSpan={2}>
                                        <h6 className="font-weight-bolder mb-0">
                                          {showPassword2[mainuser.terminal_id]
                                            ? mainuser.terminal_pass
                                            : mainuser.terminal_pass.replace(
                                              /.(?=.{0})/g,
                                              "*"
                                            )}
                                        </h6>
                                      </td>
                                      <td>
                                        <div className="ms-n5">
                                          <i
                                            className={`fa ${showPassword2[mainuser.terminal_id]
                                              ? "fa-eye-slash"
                                              : "fa-eye"
                                              } password-icon`}
                                            onClick={() => handlePasswordClick2(mainuser.terminal_id)} />
                                        </div>
                                      </td>
                                      {/* <td>
  {balance.map((balanceamount) => {
    if (balanceamount.terminal_id === mainuser.terminal_id) {
      return (
        <tr>
          <td>
            <div className="d-flex flex-column justify-content-center">
              <h6 className="font-weight-bolder mb-0 ms-3">
                {balanceamount.total_amount}
              </h6>
            </div>
          </td>
        </tr>
      );
    } else {
      return null; 
    }
  })}
</td> */}

                                      <td>
                                        <div className="d-flex flex-column justify-content-center">
                                          {mainuser.entrydate ? (
                                            <h6 className="font-weight-bold mb-0 ">
                                              {new Date(mainuser.entrydate).toLocaleDateString("en-GB")}{" "}
                                            </h6>
                                          ) : (
                                            <p className="text-bold mb-0 " style={{ fontSize: "13px" }}>No entry date</p>
                                          )}
                                        </div>
                                      </td>
                                      <td className="ms-2">
                                        <button
                                          className="btn btn-outline-dark d-flex  mb-0 me-3 p-1 ms-2"
                                          style={{ color: "black", border: "1px solid green" }}
                                          target="_blank"
                                          onClick={() =>AddmodalTerminalAmount(mainuser.terminal_id)}    
                                        >
                                          <i
                                            className="fa fa-inr fa-lg mt-1 me-2"
                                            style={{ color: "black" }}
                                            aria-hidden="true"></i>Add Profit
                                        </button>
                                      </td>
                                      <td className="ms-2">
                                        <button
                                          className="btn btn-outline-dark d-flex  mb-0 me-3 p-1 ms-2"
                                          style={{
                                            color: "black",
                                            border: "1px solid green",
                                          }}
                                          target="_blank"
                                          onClick={() =>
                                            Addbalance(mainuser.terminal_id)
                                          }
                                          href="https://www.creative-tim.com/builder?ref=navbar-material-dashboard"
                                        >
                                          <i
                                            className="fa fa-inr fa-lg mt-1 me-2"
                                            style={{ color: "black" }}
                                            aria-hidden="true"
                                          ></i>
                                          Balance
                                        </button>
                                      </td>
                                    </tr>
                                    {selectedUser && selectedUser.terminal_id === mainuser.terminal_id && (
                                      <>
                                        <tr>
                                          <td colSpan={2}>
                                            <table
                                              className="table table-striped table-bordered "
                                              style={{ width: "100px" }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                    User Name
                                                  </th>
                                                  <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                    Percentage
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {usercommission.map(
                                                  (commission, i) => (
                                                    <tr key={i}>
                                                      <td>
                                                        <h6 className=" font-weight-bold mb-0 ms-4">
                                                          {commission.user.name}
                                                        </h6>
                                                      </td>
                                                      <td>
                                                        <h6 className=" font-weight-bold mb-0 ms-5">
                                                          {commission.sharing_per}
                                                        </h6>
                                                      </td>
                                                    </tr>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </>
                                    )}
                                  </React.Fragment>
                                )
                            )}
                          </tbody>
                        </table>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="" style={{ border: '', padding: '5px', borderRadius: '5px',marginTop: '45px' }}>
                          <label htmlFor="recordsPerPage">Records per Page:</label>
                          <select
                            id="recordsPerPage"
                            value={recordsPerPage}
                            onChange={handleRecordsPerPageChange}>
                            <option value="100">100</option>
                            <option value="150">150</option>
                            <option value="200">200</option>
                          </select>
                        </div>

                        <nav className="mt-5 me-3 d-flex justify-content-end">
                          <ul className="pagination">
                            <li className="page-item">
                              <a href="#" className="page-link" onClick={prePage}>prev</a>
                            </li>

                            {
                              numbers.map((n, i) => (
                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                  <a href="#" className="page-link" onClick={() => changeCPage(n)}>{n}</a>
                                </li>
                              ))
                            }

                            <li className={`page-item ${currentPage === totalPage ? 'disabled' : ''}`}>
                              <a href="#" className="page-link" onClick={nextPage}>next</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                        </>
                      ) : (
                        <table className="table align-items-center mb-0">
                          <thead>
                            <tr>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                #
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                User Name
                              </th>
                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Terminal Id
                              </th>

                              <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                Entry Date
                              </th>
                            </tr>
                          </thead>
                          <tbody className="table align-items-center">
                            {isLoading ? (
                              <tr>
                                <td colSpan="6" className="text-center">
                                  <span className="loader"></span>
                                </td>
                              </tr>
                            ) : (
                              addmainusers.length > 0 && 
                              addmainusers?.map((mainuser, i) =>mainuser.user.status !== "Deactive" && (
                                    <React.Fragment key={mainuser.terminal_id}>
                                      <tr>
                                        <td
                                          style={{ cursor: "pointer" }}
                                          onClick={() => handleRowClick(mainuser)}>
                                          <i className={`fa ${selectedUser?.terminal_id ===mainuser?.terminal_id
                                              ? "fa-arrow-down": "fa-arrow-right"}`} aria-hidden="true"/>
                                        </td>
                                        <td>
                                          <h6 className=" font-weight-bold mb-0 ms-4">{mainuser.user.name}</h6>
                                        </td>
                                        <td>
                                          <NavLink to={`/activeposition/${mainuser.terminal_id}`} target="_blank">
                                            <h6 className=" font-weight-bold mb-0 ms-5" style={{ cursor: "pointer", color: getStatusColor(mainuser.t_status)}} >
                                              {mainuser.terminal_id}
                                            </h6>
                                          </NavLink>
                                        </td>

                                        <td>
                                          <div className="d-flex flex-column justify-content-center">
                                            <h6 className=" font-weight-bold mb-0 ms-2">
                                              {new Date(mainuser.entrydate).toLocaleDateString("en-GB")}
                                            </h6>
                                          </div>
                                        </td>
                                      </tr>
                                      {selectedUser &&
                                        selectedUser.terminal_id === mainuser.terminal_id && (
                                          <>
                                            <tr>
                                              <td colSpan={2}>
                                                <table
                                                  className="table table-striped table-bordered "
                                                  style={{ width: "100px" }}
                                                >
                                                  <thead>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                      User Name
                                                    </th>
                                                    <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                                                      Percentage
                                                    </th>
                                                  </thead>
                                                  <tbody>
                                                        <tr>
                                                          <td>
                                                            <h6 className=" font-weight-bold mb-0 ms-4">
                                                              {mainuser.user.name}
                                                            </h6>
                                                          </td>
                                                          <td>
                                                            <h6 className=" font-weight-bold mb-0 ms-5">
                                                            {usercommission[0].sharing_per}
                                                            </h6>
                                                          </td>
                                                        </tr>
                                                  </tbody>
                                                </table>
                                              </td>
                                            </tr>
                                          </>
                                        )}
                                    </React.Fragment>
                                  )
                              )
                            )}
                          </tbody>
                        </table>
                      )}
                     
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {addmainusers?.length > 0 && modalamount ? (
        <Modal
          size="ig"
          isOpen={modalamount}
          toggle={() => setAddamount(!modalamount)}
        >
          <ModalHeader toggle={() => setAddamount(!modalamount)}>
            <form
              style={{ margin: "10px 70px" }}
              className="login"
              onSubmit={(event) => AddTerminalAmountsubmit(event)}
            >
              <div className="box" style={{}}>
                <label style={{ fontSize: "16px", display: "block" }}>
                  Amount<span className="text-danger" style={{ margin: "4px" }}>*</span>
                </label>
                <input
                  type="text"
                  className="input rounded"
                  value={amount}
                  placeholder="Enter your Amount"
                  onChange={(event) => addterminalamounthandlechange("amount", event)}
                  style={{ width: "100%", height: "40px", fontSize: "16px" }}
                />

                {errors.amount && (
                  <p style={{ marginBottom: "3px" }} className="errorMsg text-danger text-bold">{errors.amount}</p>
                )}
              </div>

              <div className="modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary "
                  data-bs-dismiss="modal"
                  onClick={() => setAddamount(!modalamount)}
                >
                  Close
                </button>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </ModalHeader>
        </Modal>
      ) : null}

      {addmainusers?.length > 0 && modalbalance ? (
        <Modal
          size="ig"
          isOpen={modalbalance}
          toggle={() => setAddbalance(!modalbalance)}
        >
          <ModalHeader toggle={() => setAddbalance(!modalbalance)}>
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "28px" }}>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  style={{ backgroundColor: "red", marginRight: "10px" }}
                  onClick={() => {
                    // setAddbalance(!modalbalance);
                    setShowAddBalance("addBalance");
                  }}
                >
                  Add Balance<span className="text-danger" style={{ margin: "4px" }}>*</span>
                </button>

                <button
                  type="button"
                  className="btn btn-primary "
                  style={{ backgroundColor: "blue" }}
                  data-bs-dismiss="modal"
                  onClick={() => setShowAddBalance("withdrawal")}
                >
                  Withdrawal
                </button>
              </div>
            </div>
          </ModalHeader>
          {showAddBalance === "addBalance" && (
            <ModalBody>
              <form
                style={{ margin: "10px 70px" }}
                className="login"
                onSubmit={(event) => Addbalancesubmit(event)}
              >
                <div className="box" style={{}}>
                  <label style={{ fontSize: "16px", display: "block" }}>
                    Add Balance Amount<span className="text-danger" style={{ margin: "4px" }}>*</span>
                  </label>

                  <input
                    type="text"
                    className="input rounded"
                    value={balanceamount}
                    placeholder="Enter your Amount"
                    onChange={(event) =>
                      adduserbalanceechange("balanceamount", event)
                    }
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  />
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </ModalBody>
          )}

          {showAddBalance === "withdrawal" && (
            <ModalBody>
              <form
                style={{ margin: "10px 70px" }}
                className="login"
                onSubmit={(event) => Withdrawbalancesubmit(event)}
              >
                <div className="box">
                  <label style={{ fontSize: "16px" }}> Add Amount <span className="text-danger" style={{ margin: "4px" }}>*</span></label>

                  <select
                    className="input rounded"
                    // value={role}
                    // onChange={(event) => handlechange("role", event)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  >
                    <option value="">Select Curruncy Type</option>

                    <option value="admin">USD</option>
                    <option value="user">INR</option>
                  </select>
                </div>

                <div className="box mt-2" style={{}}>
                  <input
                    type="text"
                    className="input rounded"
                    value={withdrawAmount}
                    placeholder="Enter your Amount"
                    onChange={(event) => setWithdrawAmount(event.target.value)}
                    style={{ width: "100%", height: "40px", fontSize: "16px" }}
                  />
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </ModalBody>
          )}
        </Modal>
      ) : null}
    </>
  );
}
