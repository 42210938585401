import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import './Slider.css'
import { showAlert, showConfirmationDialog } from '../service/alert_service';
import { Cookies  } from "react-cookie";

export default function Sidebar(props) {

  const cookies = new Cookies();

  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userRole = parsedUserDetail ? parsedUserDetail?.role : null;
  const userEmail = parsedUserDetail ? parsedUserDetail.email : null;

  const handleLogout = async () => {
    const confirmation = await showConfirmationDialog("Are you sure you want to log out?","","warning");
    if (confirmation) {
      showAlert("Logged out successfully.", "success");
      localStorage.clear();
      cookies.remove("token");
      window.location.href = "/login";
    }
  };

  return (
    <>

      <aside
        className={`resp-side sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 fixed-start ms-1 bg-gradient-dark ${props.isSidebarOpen ? 'd-block' : 'd-none'}`}
        id="sidenav-main" style={{zIndex:"3"}}>

        <div className="sidenav-header">
          {/* <i className="fas fa-times p-3 cursor-pointer text-white opacity-5 position-absolute end-0 top-0 d-none d-xl-none" aria-hidden="true" id="iconSidenav"></i> */}
          <a className="navbar-brand m-0" style={{ display: 'flex', justifyContent: 'space-between' }}>

            <div className="" >
              {
                userEmail === "superadmin@gmail.com" ? (
                  <div className="ms-2" style={{ display: "flex", flexDirection: "column", width: "55px",marginTop:"-19" }}>
                    <label style={{ fontSize: "13px", color: "white",marginBottom:"15px" }}><b>Email:</b>{userEmail}</label>
                    <label style={{ fontSize: "13px", color: "white", marginTop: "-13px" }}><b>Role:</b>SuperAdmin</label>
                  </div>
                ) : (
                  <>
                    <div className="ms-2" style={{ display: "flex", flexDirection: "column", width: "55px",marginTop:"-19" }}>
                    <label style={{ fontSize: "13px", color: "white" }}><b>Email:</b>{userEmail}</label>
                    <label style={{ fontSize: "13px", color: "white", marginTop: "-13px" }}><b>Role:</b>{userRole}</label>
                  </div>

                  </>
                )
              }
            </div>
          </a>
        </div>
        <hr className="horizontal light mt-0 mb-2" />
        <div className="w-auto">
          <ul className="navbar-nav">
            <li className="nav-item">
              <NavLink to="/" className="nav-link text-white hov">
                <div className="text-white text-center  me-2 d-flex align-items-center justify-content-center">
                  <span className="nav-link-text ms-0 text-white " >Dashboard</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/userdetails/mainusers" className="nav-link text-white hov">
                <div className="text-white text-center  me-2 d-flex align-items-center justify-content-center">
                  <span className="nav-link-text ms-0 text-white " >Users</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item ">
              <NavLink to="/userdetails/Ib" className="nav-link text-white hov">
                <div className="text-white text-center  me-2 d-flex align-items-center justify-content-center">
                  <span className="nav-link-text ms-0 text-white " >IB Users</span>
                </div>
              </NavLink>
            </li>
            {
              userEmail === "superadmin@gmail.com" && (
                <>
                <li className="nav-item">
                  <NavLink to="/userdetails/bot" className="nav-link text-white">
                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                      <span className="nav-link-text ms-0">Pending Account</span>
                    </div>
                  </NavLink>
                </li>
                 <li className="nav-item">
                 <NavLink to="/cycle" className="nav-link text-white " >
                   <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                     <span className="nav-link-text ms-0">Cycle</span>
                   </div>
                 </NavLink>
               </li>
               <li className="nav-item">
                 <NavLink to="/tsource" className="nav-link text-white " >
                   <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                     <span className="nav-link-text ms-0">T Source</span>
                   </div>
                 </NavLink>
               </li>
               <li className="nav-item">
                 <NavLink to="/tpc" className="nav-link text-white " >
                   <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                     <span className="nav-link-text ms-0">T Pc</span>
                   </div>
                 </NavLink>
               </li>
               <li className="nav-item">
                 <NavLink to="/currency" className="nav-link text-white " >
                   <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">
                     <span className="nav-link-text ms-0">Currency</span>
                   </div>
                 </NavLink>
               </li>
               </>
              )
            }
            <li className="nav-item">
              <NavLink to="/terminalaccounts" className="nav-link text-white " href="../pages/billing.html">
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Terminal</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/transaction" className="nav-link text-white " >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Profit Transaction</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/history" className="nav-link text-white " >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Profit History</span>
                </div>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/userlist" className="nav-link text-white " >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Profit Amount</span>
                </div>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/balance" className="nav-link text-white " >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Balance</span>
                </div>
              </NavLink>
            </li>

            {
              userEmail === "superadmin@gmail.com" && (
                <>
                <li className="nav-item">
                  <NavLink to="/changepassword" className="nav-link text-white " >
                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                      <span className="nav-link-text ms-0">Change User Account Password</span>
                    </div>
                  </NavLink>
                </li>
                  <li className="nav-item">
                  <NavLink to="/changesuperpassword" className="nav-link text-white " >
                    <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                      <span className="nav-link-text ms-0">Change Super Admin Password</span>
                    </div>
                  </NavLink>
                </li>
                </>
              )
            }

            <li className="nav-item">
              <div className="nav-link text-white " onClick={handleLogout} >
                <div className="text-white text-center me-2 d-flex align-items-center justify-content-center">

                  <span className="nav-link-text ms-0">Logout</span>
                </div>
              </div>
            </li>

          </ul>
        </div>

      </aside>

    </>
  )
}

