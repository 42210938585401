import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { getMethod, postMethod } from "../service/base_service";
import { Apiurl } from "../service/apiRoute";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Showdilog, showAlert } from "../service/alert_service";
import { NavLink, Navigate } from "react-router-dom";


export default function Balance(props) {
  const [balance, setBalance] = useState([]);

  const loginUserDetail = localStorage?.getItem("user");
  const parsedUserDetail = loginUserDetail ? JSON.parse(loginUserDetail) : null;
  const userEmail = parsedUserDetail ? parsedUserDetail?.role: null; 
  
  const loginUserDetail1 = localStorage?.getItem("user");
  const parsedUserDetail1 = loginUserDetail1 ? JSON.parse(loginUserDetail1) : null;
  const userEmail1 = parsedUserDetail1 ? parsedUserDetail1.email : null; 


  const [addmainusers, setMainUsers] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [userId, setUserId] = useState("");
  const [modalamount, setAddamount] = useState(false);
  const [errors, setErrors] = useState({});
  const [amount, setAddterminalamount] = useState("");



  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   setToken(token);
  // }, []);

  useEffect(() => {
    // Fetch user data from API and update the state
    fetchbalanceammount();
  }, []);

  const fetchbalanceammount = async () => {
    try {
      
      // Make an API request to fetch user data
      const response = await getMethod(Apiurl.usergetbalance);
      if (response) {
        setBalance(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }


  };


  const addwithdrawalamounthandlechange = (terminalId) => {
    setAddamount(true);
    setUserId(terminalId);
  };
  


  const AddmodalwithdrawalAmount = (userId) => {
    setAddamount(true);
    setUserId(userId);
  };

  const AddwithdrawalAmountubmit = async (event) => {
    event.preventDefault();

    const errors = {};
    if (!amount) {
      errors.amount = "Please Enter  Amount";
    }

    setErrors(errors);
    if (Object.keys(errors).length !== 0) {
      showAlert("Please enter proper data.", "error");
      return;
    }

    const res = {
      user_id: userId,
      amount,
      
    };

    const response = await postMethod(Apiurl.terminalamount, res);
    if (response.success) {
      if (response.success) {
        Showdilog("Amount added successfully.");
       

       Navigate("/terminalaccounts")
        setAddterminalamount('')
        setUserId('')
      } else {
        Showdilog(response.message, "failed");
      }
    }
  };
  const getStatusColor = (tstatus) => {
    if (tstatus === "active") {
      return "green";
    } else if (tstatus === "stop") {
      return "yellow";
    } else if (tstatus === "deleted") {
      return "red";
    }
  };

  return (
    <>
        <Header/>
      <Sidebar isSidebarOpen={props.isSidebarOpen}/>
      <main className={`position-relative max-height-vh-100 h-100 border-radius-lg ${props.isSidebarOpen ? 'main-content' : ''}`}>
        <div className="container-fluid py-4" style={{ marginTop: "-20px" }}>
          <div className="row">
            <div className="col-12">
              <div className="card my-4">
                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2">
                  <div className="bg-info bg-gradient shadow-primary border-radius-lg d-flex justify-content-between" style={{height:"55px"}}>
                    <h6 className="text-white text-capitalize ps-3 mt-3">
                      BALANCE DETAIL
                    </h6>
                  </div>
                </div>
                <div className="card-body px-0 pb-2">
                  <div className="table-responsive p-0">
                    
                   {
                             
                   userEmail1 === "superadmin@gmail.com" ||  userEmail === "Admin" ? (
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              User Name
                            </th>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Terminal Id
                            </th>

                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Balance Amount
                            </th>
                           
                          </tr>
                        </thead>
                        <tbody className="table align-items-center">
                          {
                          isLoading ? (
                            <tr>
                          <td colSpan="6" className="text-center">
                            <span className="loader"></span>
                          </td>
                        </tr>
                          ) : (
                          balance.map((userbalance, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {userbalance?.user_main?.user.name}
                                  </h6>
                                </td>
                                <td>
                                <NavLink to={`/activeposition/${userbalance?.terminal_id}`}target="_blank">  <h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(userbalance.user_main.t_status),}} >
                                      {userbalance?.terminal_id}
                                    </h6>
                                    </NavLink>
                                   
                                </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {userbalance?.total_amount}
                                  </h6>
                                </td>
                               
                              </tr>
                            </React.Fragment>
                          )))}
                        </tbody>
                      </table>
                    ) : ( 
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              User Name
                            </th>
                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Terminal Id
                            </th>

                            <th className="text-uppercase text-secondary text-xs font-weight-bolder">
                              Balance
                            </th>
                            
                          </tr>
                        </thead>
                        <tbody className="table align-items-center">
                          {
                          isLoading ? (
                            <tr>
                            <td colSpan="6" className="text-center">
                              <span className="loader"></span>
                            </td>
                          </tr>
   // replace this with your custom loader component
                          ) : (
                          balance.map((userbalance, index) => (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {userbalance?.user_main?.user.name}
                                  </h6>
                                </td>
                                <td>
                                <NavLink to={`/activeposition/${userbalance?.terminal_id}`}target="_blank">  <h6 className=" font-weight-bold mb-0 ms-5" style={{cursor:"pointer", color: getStatusColor(userbalance.user_main.t_status),}} >
                                      {userbalance?.terminal_id}
                                    </h6>
                                    </NavLink>
                                   
                                </td>
                                <td>
                                  <h6 className="font-weight-bold mb-0 ms-2">
                                    {userbalance?.total_amount}
                                  </h6>
                                </td>
                               
                              </tr>
                            </React.Fragment>
                          )))}
                        </tbody>
                      </table>

                    )}
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>


    </>
  );
}
